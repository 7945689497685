import React, { useContext } from "react";
import {
    BytenitecustomerServerInfoResponse,
    CommonFilter,
    CommonFilterCondition,
    CommonGenericResponse,
    CustomerJobsRunningTasksResponse,
    CustomerRedeemCouponResponse,
    CustomerSchemaResponse,
    DataSourceDataSource,
    JobAppParams,
    JobJob,
    JobsDataSourceInfoResponse,
    JobsJobEstimationResponse,
    JobsJobPreset,
    JobsJobResult,
    JobsJobsResponse,
    StatsOverviewStatsResponse,
    UpdateSubscriptionRequestUpdateSubscriptionRequestBody as UpdateSubscriptionRequestBody,
    WalletCreateSubscriptionRequest,
    WalletCreateSubscriptionResponse,
    WalletExchangeRateResponse,
    WalletGetBalanceResponse,
    WalletPlan,
    WalletSubscription,
    WalletTopUpResponse,
    WalletTransactionHistoryResponse,
    WalletTransactionResponse,
} from "../../client";

export interface ApiContextInterface {
    serverInfo: () => Promise<BytenitecustomerServerInfoResponse>;
    getSchema: (id: string) => Promise<CustomerSchemaResponse>;
    serverUrl: string;
    getJobs: (limit: number, offset: number) => Promise<Array<JobJob>>;
    getJobsFiltered: (jobsHistoryFilters: any) => Promise<JobsJobsResponse>;
    getJobsTasks: (
        jobsIds: string[]
    ) => Promise<CustomerJobsRunningTasksResponse>;
    getJobStateFilters: (
        states: string[],
        condition: CommonFilterCondition,
        isHistory: boolean
    ) => CommonFilter;
    createJobFilter: (
        value: any,
        field: string,
        condition: CommonFilterCondition
    ) => CommonFilter;
    getDefaultLimitFilters: () => number;
    saveJobDataSource: (
        job: JobJob,
        source: any,
        destination: any
    ) => Promise<JobJob>;
    getDataSourceInfo: (
        dataSource?: DataSourceDataSource,
        listFiles?: boolean
    ) => Promise<JobsDataSourceInfoResponse>;
    saveJobName: (job: JobJob, jobName: string) => Promise<boolean>;
    getJobEstimation: (jobId: string) => Promise<JobsJobEstimationResponse>;
    setLocalFileUploaded: (jobId: string) => Promise<CommonGenericResponse>;
    createNewJob: (
        templateId?: string,
        dataSource?: DataSourceDataSource,
        dataDestination?: DataSourceDataSource,
        params?: JobAppParams,
        name?: string
    ) => Promise<JobJob>;
    saveJobParameters: (
        job: JobJob,
        jobParams: JobAppParams
    ) => Promise<JobJob>;
    runJob: (jobId: string) => Promise<CommonGenericResponse>;
    abortJob: (jobId: string) => Promise<CommonGenericResponse>;
    deleteJob: (jobId: string) => Promise<CommonGenericResponse>;
    getJobResults: (jobId: string) => Promise<JobsJobResult[]>;
    getStatsOverview: (
        startDate: Date,
        endDate: Date
    ) => Promise<StatsOverviewStatsResponse>;
    copyJob: (jobId: string) => Promise<JobJob>;
    getHtmlFormDataFromDataSource: (job: JobJob) => any;
    getJobById: (jobId: string) => Promise<JobJob>;
    setCreatingJob: (val: boolean, callback: () => void) => void;
    getCreatingJob: () => boolean;

    redeemPromoCode: (code: string) => Promise<CustomerRedeemCouponResponse>;
    createTopUpRequest: (
        currency: string,
        amount: Number
    ) => Promise<WalletTopUpResponse>;
    cancelTopUpRequest: (
        topUpRequestId: string,
        cancelMessage: string
    ) => Promise<CommonGenericResponse>;
    getBalance: () => Promise<WalletGetBalanceResponse>;
    getTransactionList: () => Promise<WalletTransactionResponse>;
    getPlans: () => Promise<WalletPlan[]>;
    getExchangeRate: (
        currency: string,
        currencyAmount: Number,
        amount: Number
    ) => Promise<WalletExchangeRateResponse>;
    getActiveSubscription: () => Promise<WalletSubscription>;
    createSubscription: (
        body: WalletCreateSubscriptionRequest
    ) => Promise<WalletCreateSubscriptionResponse>;
    updateSubscription: (
        id: string,
        body: UpdateSubscriptionRequestBody
    ) => Promise<WalletCreateSubscriptionResponse>;
    cancelSubscription: (id: string) => Promise<CommonGenericResponse>;

    uploadJobFile: (job: JobJob, selectedFile: File) => Promise<void>;
    hasPendingUpload: (jobId: string) => boolean;
    pendingUploadProgress: (jobId: string) => number;
    cancelUpload: (jobId: string) => boolean;

    defaultTemplateId: () => string;
    defaultSchemaId: () => string;

    getJobPresets: (jobId: string) => Promise<JobsJobPreset[]>;
    getBillingOverView: () => Promise<WalletTransactionHistoryResponse>;
    getBillingPortal: () => Promise<any>;
}

const ApiContext = React.createContext<ApiContextInterface | null>(null);

export const useApiContext = () => useContext(ApiContext);

export default ApiContext;
