import React from 'react';
import {TransactionProps} from "./types";
import {
    BarChart,
    XAxis,
    YAxis,
    ResponsiveContainer,
    Bar,
    CartesianGrid,
    Tooltip,
    Rectangle
} from 'recharts';
import {
    colorLooper,
    domainFormatter,
    tickFormatter,
    transactionsToChartData
} from "../../../../utils/helpers";
import CustomToolTip from "./CustomToolTip";

export const chartColors: string[] = [
    "#F7BFBF",
    "#F7D68C",
    "#B4B4F6",
    "#F1A9F1"
];

export const grayVariant: string[] = [
    "#A3A3A3",
    "#DBDBDB",
    "#EDEDED",
    "#F7F7F7"
];

const BillingOverviewChart = (
    props: {
        transactions: TransactionProps[],
        billingMonth: Date,
        selectedDay?: string,
        setSelectedDay?: (day: string | undefined) => void
    }
) => {
    const {transactions, billingMonth, selectedDay, setSelectedDay} = props;

    const chartData = transactionsToChartData(transactions, billingMonth) ?? [];

    const legends = chartData.length > 0 ? Object.keys(chartData[0]).filter((key) => key !== "name") : [];

    return (
        <ResponsiveContainer height={250} width="100%">
            <BarChart
                width={1080}
                data={chartData}
                onClick={(data) => {
                    setSelectedDay?.(data?.activeTooltipIndex ? chartData[data?.activeTooltipIndex].name : undefined);
                }}
            >
                <CartesianGrid stroke="#D9D9FF" vertical={false} strokeWidth={1}/>
                <XAxis
                    dataKey="name"
                    color="#9091FE"
                    width={100}
                    allowDataOverflow
                    minTickGap={0}
                    tickLine={false}
                    tick={{
                        fill: "#9091FE",
                        fontSize: "12px",
                        fontWeight: 600,
                        fontFamily: "Gilroy"
                    }}
                    style={{
                        userSelect: "none",
                    }}
                />
                <YAxis
                    domain={([dataMin, dataMax]) => domainFormatter(dataMin, dataMax)}
                    tick={{
                        fill: "#9091FE",
                        fontSize: "12px",
                        fontWeight: 600,
                        fontFamily: "ByteNite Symbols",
                    }}
                    style={{
                        userSelect: "none",
                    }}
                    width={100}
                    axisLine={false}
                    tickCount={5}
                    tickLine={false}
                    tickFormatter={(value: number) => tickFormatter({tickValue: value})}
                    includeHidden
                />
                <Tooltip
                    content={(props) => <CustomToolTip {...props} legends={legends} />}
                />
                {legends.map((legend, index) => {
                    return (
                        <Bar
                            dataKey={legend}
                            stackId="chart-data"
                            fill={colorLooper(index, chartColors)}
                            key={legend}
                            name={legend}
                            shape={(props: Record<string, any>) => {
                                const todayIsSelected = selectedDay === props.name;
                                const color = todayIsSelected ? colorLooper(index, chartColors) : colorLooper(index, grayVariant);
                                return (
                                    <Rectangle
                                        {...props}
                                        fill={selectedDay ? color : colorLooper(index, chartColors)}
                                    />
                                );
                            }}
                        />
                    );
                })}
            </BarChart>
        </ResponsiveContainer>
    )
};

export default BillingOverviewChart;