// React and Material-UI imports
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Paper,
  Skeleton,
  Snackbar,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { withStyles } from "@mui/styles";

// Custom and external module imports
import {
  EVENT_JOB_UPLOAD_COMPLETED,
  withApi,
} from "@/hoc/ApiProvider/Provider";
import { uiSchema as uiSchemaVideoTranscoding } from "@bytenite/components/src/schemas/videoTranscoding";
import { uiSchema as uiSchemaJobData } from "@bytenite/components/src/schemas/jobDataWithoutDestination";
import { pbTypesMap } from "@bytenite/components/src/components/formComponents/JobDataForm/types";

// SVG icon imports
import { ReactComponent as ArrowLeftPink } from "@bytenite/components/src/assets/jobIcons/arrow-left-pink.svg";
import { ReactComponent as ArrowRightGray } from "@bytenite/components/src/assets/jobIcons/arrow-right-gray.svg";
import { ReactComponent as ArrowRightWhite } from "@bytenite/components/src/assets/jobIcons/arrow-right-white.svg";

//SCSS import
import "@bytenite/components/src/styles/sass/containers.scss";

// Component imports
import JsonSchemaForm from "@bytenite/components/src/components/JsonSchemaForm/JsonSchemaForm";
import JobPresetForm, {
  JobPresetUnpackaged,
} from "@bytenite/components/src/components/formComponents/JobPresetForm";
import ReviewCreateJob from "@bytenite/components/src/components/ReviewCreateJob";
import JobDataForm from "@bytenite/components/src/components/formComponents/JobDataForm";
import FileUploadProgress from "../components/FileUploadProgress";
import { useAuthContext } from "@bytenite/auth/src/hoc/Auth/context";
import { withAuth } from "@bytenite/auth/src/hoc/Auth/Provider";
import { AddSecretForm } from "@/components/SecretsTable";
import * as modals from "react-router-dom";
import { useModals } from "@bytenite/components/src/hoc/Modals/Provider";

const IDB_BYTENITE = "byteniteJobs";
const IDB_UPLOADS = "jobsUploads";

const styles = (theme) => ({
  root: {},
  buttonGroup: {
    ["& .MuiButton-root"]: {
      margin: theme.spacing(1),
    },
  },
});

const JobEditor = ({ api, auth, classes, ...props }) => {
  const modals = useModals();

  const presets = [JobPresetUnpackaged, "HLS", "DASH", "CMAF"];
  const steps = ["Input", "Template", "Outputs", "Review"];

  const optionsPerformance = {
    enumOptions: [
      { value: "low", label: "Low" },
      { value: "balanced", label: "Balanced" },
      { value: "high", label: "High" },
    ],
  };

  const defaultSchema = api.defaultSchemaId(); //'resize-encode'

  const params = useParams();
  const jobId = params.id;

  const [notification, setNotification] = useState({ open: false, text: "" });

  const [pendingJob, setPendingJob] = useState({});
  const [errorEstimation, setErrorEstimation] = useState(false);

  const [jobDataForm, setJobDataForm] = useState(jobId ? null : {}); //null to wait the api request before rendering

  const [jobName, setJobName] = useState("");
  // const [sampleVideo, setSampleVideo] = useState('')

  const [jobCostEstimation, setJobCostEstimation] = useState(0);

  const [jobParams, setJobParams] = useState({
    preset: "",
    data: {},
    selectedOutputs: [],
    schema: "",
  });
  const [jobConfigForm, setJobConfigForm] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [runPending, setRunPending] = useState(false);
  const [isSavingToDB, setIsSavingToDB] = useState(false);
  const [showJson, setShowJson] = useState(false);

  const [schema, setSchema] = useState(null);
  const [activeStep, setActiveStep] = useState(0);

  const [liveValidateDataSource, setLiveValidateDataSource] = useState(false);
  const [liveValidateParams, setLiveValidateParams] = useState(false);

  const [balance, setBalance] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreviouslyUploaded, setFilePreviouslyUploaded] = useState(false);

  const [indexedDb, setIndexedDb] = useState(null);
  const [fileIsUploading, setFileIsUploading] = useState(
    jobId ? api.hasPendingUpload(jobId) : false
  );
  const [fileUploadState, setFileUploadState] = useState("");
  const [checkEstimationEnabled, setCheckEstimationEnabled] = useState(false);
  const [showUploadNotification, setShowUploadNotification] = useState(true);

  const [secrets, setSecrets] = useState([]);

  const jobDataFormRef = useRef();
  const jobConfigFormRef = useRef();

  const [jobPresets, setJobPresets] = useState([]);

  //edit uischema to make it able update state
  uiSchemaJobData["source"]["file"]["ui:widget"] = (widgetProps) => {
    return (
      <div>
        <div id="alreadyUploaded" style={{ display: "none" }}>
          <span style={{ marginRight: "0.8em" }}>
            {"Previous uploaded file"}
          </span>
          <i>
            <span id={"fileNameExist"}></span>
          </i>
          <div>
            {"Click NEW to replace it "}
            <button
              onClick={() => {
                const alreadyUploaded =
                  document.getElementById("alreadyUploaded");
                alreadyUploaded.style.display = "none";
                const inputUpload = document.getElementById("inputUpload");
                inputUpload.style.display = "block";
                setSelectedFile(null);
                setFilePreviouslyUploaded(false);
              }}
            >
              NEW
            </button>
          </div>
        </div>
        <input
          type="file"
          id="inputUpload"
          required={widgetProps.required}
          accept="video/mp4,video/mkv,video/mov,video/webm,video/x-m4v,video/*"
          onChange={(event) => {
            const file = event.target.files[0];
            setSelectedFile(file);
            widgetProps.onChange(file.name); // {"name": file.name, "size": file.size, "type": file.type}
          }}
        />
      </div>
    );
  };

  const validateForm = (formRef) => {
    if (formRef.current) {
      return formRef.current.validate(formRef.current.state.formData);
    }
    return { errors: [], errorSchema: [] };
  };

  const getBalance = () => {
    return api.getBalance().then((resp) => {
      const availableBalance = parseInt(resp.availableBalance);
      setBalance(availableBalance);
    });
    //getBalance()
  };

  const navigate = useNavigate();
  const navigationState = useLocation().state;

  const loadSecrets = async () => {
    return auth.listSecrets().then((resp) => {
      setSecrets(resp);
    });
  };
  const createSecret = (platform) => {
    //modals.OkOnly("test", "test modal")
    modals
      .Form(
        `Add ${platform} credentials`,
        (changeHandler, defaultValues) =>
          AddSecretForm(changeHandler, defaultValues, { secretType: platform }),
        { size: "sm", disableTypography: false }
      )
      .then(async ({ ok, data }) => {
        if (ok) {
          console.log("data", data);
          const { secretKey, ...secret } = data;
          await auth.saveSecret({ ...secret, secretType: platform }, secretKey);
          loadSecrets();
        }
      })
      .catch((reason) => {
        if (!reason?.ok) {
          console.log("error", reason);
        }
      });
  };

  useEffect(() => {
    api
      .getJobById(jobId)
      .then((respJob) => {
        setPendingJob(respJob);
        setJobDataForm(api.getHtmlFormDataFromDataSource(respJob));
        setJobParams(
          respJob.params || {
            preset: "",
            data: {},
            selectedOutputs: [],
            schema: "",
          }
        );
        setJobConfigForm(respJob.params?.data);
        setActiveStep(0);
        setJobName(respJob.name);
        //if we already created a new job
        //we ask the user to confirm
        if (navigationState && navigationState.newJob === true) {
          api.setCreatingJob(true, () => {});
        }
      })
      .catch((err) => {
        console.error(err);
      });
    loadSecrets();
  }, [jobId]);

  //set indexedDB for eventual local uploads
  useEffect(() => {
    const requestOpenDB = indexedDB.open(IDB_BYTENITE);
    requestOpenDB.onerror = (event) => {
      console.error("[Indexed DB Error]", event);
    };
    // This handler is called when a new version of the database
    // is created, either when one has not been created before
    // or when a new version number is submitted by calling
    // window.indexedDB.open().
    // This handler is only supported in recent browsers.
    requestOpenDB.onupgradeneeded = (event) => {
      const db = event.target.result;

      db.onerror = (event) => {
        console.error("[Indexed DB Error]", event);
      };

      // Create an objectStore for this database
      const objectStore = db.createObjectStore(IDB_UPLOADS, {
        keyPath: "jobId",
      });

      // define what data items the objectStore will contain

      objectStore.createIndex("url", "url", { unique: false });
      objectStore.createIndex("file", "file", { unique: false });
      objectStore.createIndex("status", "status", { unique: false });
    };
    //if there is not
    requestOpenDB.onsuccess = (event) => {
      const db = event.target.result;
      //for future usage
      setIndexedDb(db);

      //check that for this job we have already uploaded a file
      getRecordIndexedDB(
        jobId,
        () => {},
        (result) => {
          if (result && result.file && result.status === "uploaded") {
            //TODO for asynchronous uploading use http header to get size of the file
            const formatBytes = (bytes, decimals = 2) => {
              if (!+bytes) return "0 Bytes";

              const k = 1024;
              const dm = decimals < 0 ? 0 : decimals;
              const sizes = [
                "Bytes",
                "KB",
                "MB",
                "GB",
                "TB",
                "PB",
                "EB",
                "ZB",
                "YB",
              ];

              const i = Math.floor(Math.log(bytes) / Math.log(k));

              return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${
                sizes[i]
              }`;
            };
            const textUploaded = document.getElementById("alreadyUploaded");
            textUploaded ? (textUploaded.style.display = "block") : "";
            const inputUpload = document.getElementById("inputUpload");
            inputUpload ? (inputUpload.style.display = "none") : "";
            const fileNameExist = document.getElementById("fileNameExist");
            fileNameExist
              ? (fileNameExist.textContent =
                  result.file.name +
                  " of size " +
                  formatBytes(result.file.size))
              : "";
            setSelectedFile(result.file);
            setFilePreviouslyUploaded(true);
          }
        },
        db
      );
    };
  }, []);

  useEffect(() => {
    getBalance();
    return () => {
      //when leaving the editor page
      //you don't want to be asked again
      //that you are already creating a job
      api.setCreatingJob(false, () => {});
      getBalance();
    };
  }, []);

  useEffect(() => {
    if (jobName.length === 0) {
      return;
    }

    const timer = setTimeout(() => {
      saveJobName(jobName, pendingJob);
    }, 500); // to not update the job every key pressed

    return () => {
      clearTimeout(timer);
    };
  }, [jobName]);

  const loadSchema = (schemaId) => {
    return api
      .getSchema(schemaId || defaultSchema)
      .then((resp) => {
        setSchema(resp.schema);
      })
      .catch((err) => {
        throw err;
      });
  };

  const onUpdatedJobDB = () => {
    setIsSavingToDB(false);
    setIsLoading(false);
    setActiveStep(activeStep + 1);
  };

  const runJob = (jobId) => {
    setRunPending(true);
    api.setCreatingJob(false, () => {
      api
        .runJob(jobId)
        .then((resp) => {
          setRunPending(false);
          navigate("/jobs");
          if (
            pendingJob &&
            pendingJob.dataSource.dataSourceDescriptor === "file"
          ) {
            const requestDelete = indexedDb
              .transaction([IDB_UPLOADS], "readwrite")
              .objectStore(IDB_UPLOADS)
              .delete(jobId);
            requestDelete.onsuccess = (event) => {
              console.log("++ Removed pending job file from indexed DB");
            };
          }
        })
        .catch((err) => {
          console.error(err);
          setRunPending(false);
        });
    });
  };

  const saveJobName = (jobName, jobToSave) => {
    if (jobName !== jobToSave.name) {
      setIsSavingToDB(true);
      api.saveJobName(jobToSave, jobName).then((resp) => {
        if (resp === true) {
          setIsSavingToDB(false);
        }
      });
    }
  };

  //check every 30 seconds if file finished uploading
  /*useInterval(() => {
        if(checkEstimationEnabled && !fileIsUploading){
            checkJobEstimation(jobId)
        }
    }, 30000)*/

  /*const checkJobEstimation = (jobId) => {
        if(pendingJob && pendingJob.dataSource.dataSourceDescriptor === "file"){
            getRecordIndexedDB(jobId, () => {}, (requestStoreResult) => {
                console.log("[background uploading]", requestStoreResult)
                if (requestStoreResult && requestStoreResult.status === "registered") {
                    setFileIsUploading(true)
                }else{
                    setFileIsUploading(false)
                    api.setLocalFileUploaded(jobId).then(resp => {
                        console.log("-- job set file uploaded", resp)
                        getJobEstimation(jobId)
                        setCheckEstimationEnabled(false)
                    })
                }
            })
        }
    }*/

  const getRecordIndexedDB = (id, callbackError, callbackSuccess, db) => {
    const usingDb = db ? db : indexedDb;
    const transaction = usingDb.transaction([IDB_UPLOADS], "readwrite");
    // Do something when all the data is added to the database.
    transaction.oncomplete = (event) => {
      console.log("[Indexed db] transaction open");
    };

    transaction.onerror = (event) => {
      console.error("[Indexed DB Error]", event);
      callbackError();
    };

    const objectStore = transaction.objectStore(IDB_UPLOADS);
    const requestStore = objectStore.get(id);
    requestStore.onerror = (event) => {
      console.error("[Indexed DB Error]", event);
      callbackError();
    };
    //check in the indexed db if it already exists and delete before uploading, otherwise upload
    requestStore.onsuccess = (event) => {
      callbackSuccess(requestStore.result);
    };
  };

  const addNewRecordIndexedDB = (item, sw, callbackError) => {
    const transactionNewUpload = indexedDb.transaction(
      [IDB_UPLOADS],
      "readwrite"
    );
    const objectStoreNewUpload = transactionNewUpload.objectStore(IDB_UPLOADS);
    const requestNewUpload = objectStoreNewUpload.add(item);
    requestNewUpload.onsuccess = (event) => {
      sw.sync.register("sync-new-job");
      setFileIsUploading(true);
      setCheckEstimationEnabled(true);
      onUpdatedJobDB();
    };
    requestNewUpload.onerror = (event) => {
      console.error("[Indexed DB Error]", event);
      callbackError();
    };
  };

  //returns true if two objects have same keys and values
  const compareKeys = (x, y) => {
    if (x === y) {
      return true;
    } else if (
      typeof x == "object" &&
      x != null &&
      typeof y == "object" &&
      y != null
    ) {
      if (Object.keys(x).length !== Object.keys(y).length) return false;

      for (const prop in x) {
        if (y.hasOwnProperty(prop)) {
          if (!compareKeys(x[prop], y[prop])) return false;
        } else return false;
      }

      return true;
    } else return false;
  };

  const setSampleVideo = (sampleVideo) => {
    const source = {
      dataSourceDescriptor: "url",
      params: {},
    };
    switch (sampleVideo) {
      case "bbb":
        source.params = {
          url: "https://storage.googleapis.com/video-test-public/input/bbb.mp4",
        };
        break;
      case "me":
        source.params = {
          url: "https://storage.googleapis.com/video-test-public/input/me.mp4",
        };
        break;
      case "tos":
        source.params = {
          url: "https://storage.googleapis.com/video-test-public/input/tos.mp4",
        };
        break;
      case "ub":
        source.params = {
          url: "https://storage.googleapis.com/video-test-public/input/ub.mkv",
        };
        break;
      default:
    }
    source.params["@type"] = pbTypesMap.url;
    const newForm = { ...jobDataForm };
    newForm.source = source;
    setJobDataForm(newForm);
  };

  const getJobDataFromRef = () => {
    let source = jobDataFormRef.current?.state?.formData.source;
    const destination = jobDataFormRef.current?.state?.formData.destination;

    return {
      source: pendingJob.dataSource,
      destination: pendingJob.dataDestination,
    };
  };

  const getJobConfigFromRef = () => {
    return jobConfigFormRef.current?.state.formData;
  };

  const handleSwitchTab = (filteredErrors) => {
    try {
      const property = filteredErrors[0].property;
      const outputParams = property.split(".output_params.");
      if (outputParams.length === 2) {
        let outputIndex = 0;
        try {
          outputIndex = +outputParams[0].split("[")[1].split("]")[0];
        } catch (_) {
          console.error("unexpected outputIndex error pattern");
        }
        let tabName = outputParams[1].split(".")[0];
        let tabIndex = 0;
        switch (tabName) {
          case "aspect":
            tabIndex = 0;
            break;
          case "video":
            tabIndex = 1;
            break;
          case "audio":
            tabIndex = 2;
            break;
          default:
            console.error("unexpected error tabs");
            return filteredErrors;
        }
        const buttonId = `tab-root_${outputIndex}_output_params-${tabIndex}`;
        const tabButton = document.getElementById(buttonId);
        tabButton.click();
      } else {
        console.error("unexpected form error pattern");
      }
    } catch (_) {
      console.error("missing property on error");
    }
  };

  const handleDataFormSubmit = (formData, errors) => {
    if (errors && errors.length > 0) {
      return;
    }
    const jobToSave = {
      //config: jobConfigFormRef?.current?.state.formData||pendingJob?.config,
      ...pendingJob,
    };
    api
      .saveJobDataSource(jobToSave, formData.source, formData.destination)
      .then((resp) => {
        setPendingJob(resp);
        const sourceDestination = {
          source: resp.dataSource,
          destination: resp.dataDestination,
        };
        setJobDataForm(sourceDestination);

        if (
          resp.dataSource.dataSourceDescriptor === "file" &&
          !resp.dataSource.params.uploaded
        ) {
          api.uploadJobFile(resp, formData.source.params);
          setFileIsUploading(true);
          setShowUploadNotification(true);
          setFileUploadState("uploading");
        }
        onUpdatedJobDB();
      });
  };

  const nextStep = () => {
    const jobToSave = {
      //config: jobConfigFormRef?.current?.state.formData||pendingJob?.config,
      ...pendingJob,
    };
    switch (activeStep) {
      case 0:
        if (!jobDataFormRef.current) {
          return;
        }
        jobDataFormRef.current.submit();
        api.getJobPresets(pendingJob.templateId).then((presets) => {
          setJobPresets(presets);
        });
        return;
      case 1:
        if (!jobParams) {
          return;
        }
        const schemaId =
          jobDataFormRef.current?.state.formData.schemaId || defaultSchema;
        setJobConfigForm(jobParams?.data || {});

        setIsLoading(true);
        Promise.all([
          api.saveJobParameters(jobToSave, jobParams),
          loadSchema(schemaId),
        ]).then(([]) => {
          setIsLoading(false);
          setActiveStep(activeStep + 1);
        });
        break;
      case 2:
        const data = getJobConfigFromRef();

        //to avoid resetting the form at each update
        setJobConfigForm(data);

        const errorsValidation = validateForm(jobConfigFormRef).errors;
        if (errorsValidation.length > 0) {
          handleSwitchTab(errorsValidation);
          setLiveValidateParams(true);
          return;
        }

        if (jobName === "") {
          setLiveValidateDataSource(true);
          return;
        }
        setIsSavingToDB(true);
        api
          .saveJobParameters(jobToSave, { ...jobParams, data: data })
          .then((resp) => {
            if (resp) {
              setJobParams(resp.params);
              onUpdatedJobDB();
            }
          });

        const dataVal = jobConfigFormRef.current?.state.formData;
        getBalance().then(() => {
          if (pendingJob.dataSource.dataSourceDescriptor !== "file") {
            getJobEstimation(jobId);
          } else {
            if (
              filePreviouslyUploaded ||
              pendingJob.dataSource.params?.uploaded
            ) {
              getJobEstimation(jobId);
            }
          }
        });

        break;
    }

    //console.log('save job: ', pendingJob)
    //api.saveJob(jobToSave, false)
    //setActiveStep(activeStep + 1)
  };

  const showNotification = (text) => {
    setNotification({ open: true, text: text });
  };
  const hideNotification = () => {
    setNotification({ open: false, text: "" });
  };

  const currentJobJson = () => {
    const jobData = jobDataFormRef.current ? getJobDataFromRef() : jobDataForm;
    const jobParams = jobConfigFormRef.current
      ? getJobConfigFromRef()
      : jobConfigForm;
    return {
      id: jobId,
      name: jobName,
      params: {
        data: jobParams,
      },
      dataSource: jobData.source,
      dataDestination: jobData.destination,
    };
  };
  const copyJson = () => {
    const jobJson = JSON.stringify(currentJobJson(), null, "  ");
    navigator.clipboard.writeText(jobJson);
    showNotification("Copied to clipboard");
  };

  // File upload completed event handler
  useEffect(() => {
    const cb = (e) => {
      if (e.detail.id === jobId) {
        setFileUploadState(e.detail.success ? "completed" : "failed");
        api
          .getJobById(jobId)
          .then((respJob) => {
            setJobDataForm(api.getHtmlFormDataFromDataSource(respJob));

            //if we already created a new job
            //we ask the user to confirm
            if (navigationState && navigationState.newJob === true) {
              api.setCreatingJob(true, () => {});
            }
            if (activeStep === 3) {
              getJobEstimation(jobId).then(() => {
                setFileIsUploading(false);
                setFilePreviouslyUploaded(true);
              });
            } else {
              setFileIsUploading(false);
              setFilePreviouslyUploaded(true);
            }
          })
          .catch((err) => {});
      }
    };

    document.addEventListener(EVENT_JOB_UPLOAD_COMPLETED, cb);
    return () => {
      document.removeEventListener(EVENT_JOB_UPLOAD_COMPLETED, cb);
    };
  }, [jobId, activeStep]);

  const NextButton = (props) => {
    if (isSavingToDB) {
      return <CircularProgress></CircularProgress>;
    }
    const disabled = false; //pendingJob && pendingJob.dataSource && pendingJob.dataSource.dataSourceDescriptor === "file" && !selectedFile
    return (
      <Button size={"large"} onClick={nextStep} {...props} disabled={disabled}>
        <Typography variant={"button1"}>Next</Typography>
        <Box ml={1}>
          {props.disabled ? (
            <ArrowRightGray></ArrowRightGray>
          ) : (
            <ArrowRightWhite></ArrowRightWhite>
          )}
        </Box>
      </Button>
    );
  };

  const StartButton = (props) => (
    <Button
      size={"large"}
      {...props}
      disabled={runPending || fileIsUploading}
      onClick={() => (!runPending ? runJob(pendingJob.id) : null)}
    >
      <Typography variant={"button1"}>Start job</Typography>
    </Button>
  );

  const PreviousButton = (props) => (
    <Button
      size={"large"}
      onClick={() => setActiveStep(activeStep - 1)}
      {...props}
    >
      <Typography variant={"button1"}>Previous</Typography>
      <Box ml={1}>
        <ArrowLeftPink />
      </Box>
    </Button>
  );

  const Wizard = () => (
    <>
      <Paper elevation={3}>
        <div className={"container-vertical content-start"}>
          {(fileIsUploading || filePreviouslyUploaded) &&
          showUploadNotification ? (
            <Box px={5} pt={3}>
              <FileUploadProgress
                jobId={jobId}
                api={api}
                isCompleted={filePreviouslyUploaded}
                uploadState={fileUploadState}
                onClose={() => setShowUploadNotification(false)}
              />
            </Box>
          ) : null}
          <div className={"container-horizontal content-center"}>
            <Box sx={{ my: "3em", width: activeStep < 3 ? "80%" : "100%" }}>
              <div className={"container-horizontal content-center"}>
                <Box sx={{ width: activeStep < 3 ? "100%" : "80%", mb: "1em" }}>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => (
                      <Step key={"step" + label}>
                        <StepButton
                          key={label}
                          color="inherit"
                          onClick={() => setActiveStep(index)}
                        >
                          {label}
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </div>
              <div className={"container-horizontal content-center"}>
                <Box sx={{ width: activeStep === 2 ? "100%" : "90%" }}>
                  {isLoading ? (
                    <Skeleton height={200} />
                  ) : (
                    <>
                      {activeStep === 0 ? (
                        <Box mt={4}>
                          {jobDataForm !== null ? (
                            <Box>
                              <Typography
                                variant={"h3"}
                                className={"color-blue1"}
                              >
                                Source
                              </Typography>
                              <Box mt={2}>
                                <div
                                  className={
                                    "container-horizontal content-space-between"
                                  }
                                >
                                  <Typography variant={"subtitle1"}>
                                    Sample Videos
                                  </Typography>
                                  <div>
                                    <Chip
                                      label={
                                        <Typography>Big Buck Bunny</Typography>
                                      }
                                      variant="outlined"
                                      // @ts-ignore
                                      color={"primary"}
                                      className={"subvariant-samplevideo"}
                                      onClick={() => setSampleVideo("bbb")}
                                    />
                                    <Chip
                                      label={<Typography>Meridian</Typography>}
                                      variant="outlined"
                                      // @ts-ignore
                                      color={"primary"}
                                      className={"subvariant-samplevideo"}
                                      onClick={() => setSampleVideo("me")}
                                    />
                                    <Chip
                                      label={
                                        <Typography>Tears of Steel</Typography>
                                      }
                                      variant="outlined"
                                      // @ts-ignore
                                      color={"primary"}
                                      className={"subvariant-samplevideo"}
                                      onClick={() => setSampleVideo("tos")}
                                    />
                                    <Chip
                                      label={
                                        <Typography>
                                          Unbelievable Beauty
                                        </Typography>
                                      }
                                      variant="outlined"
                                      // @ts-ignore
                                      color={"primary"}
                                      className={"subvariant-samplevideo"}
                                      onClick={() => setSampleVideo("ub")}
                                    />
                                  </div>
                                </div>
                                {/*<JsonSchemaForm ref={jobDataFormRef}
                                                                    uiSchema={uiSchemaJobData}
                                                                    schema={jobData()} noSubmit={true}
                                                                    data={jobDataFormRef.current ? getJobDataFromRef() : jobDataForm}
                                                                    liveValidate={liveValidateDataSource}
                                                    >
                                                    </JsonSchemaForm>*/}
                                <JobDataForm
                                  ref={jobDataFormRef}
                                  formData={jobDataForm}
                                  onSubmit={handleDataFormSubmit}
                                  secrets={secrets}
                                  onAddSecret={createSecret}
                                  isFileUploading={fileIsUploading}
                                />
                              </Box>
                            </Box>
                          ) : null}
                        </Box>
                      ) : null}
                      {activeStep === 1 ? (
                        <>
                          <JobPresetForm
                            presets={jobPresets}
                            jobParams={jobParams}
                            onChange={(params) => setJobParams(params)}
                          />
                        </>
                      ) : null}
                      {activeStep === 2 ? (
                        <>
                          <Box mb={4}>
                            {schema ? (
                              <JsonSchemaForm
                                ref={jobConfigFormRef}
                                schema={schema}
                                data={
                                  jobConfigFormRef.current
                                    ? getJobConfigFromRef()
                                    : jobConfigForm
                                }
                                noSubmit={true}
                                liveValidate={liveValidateParams}
                                uiSchema={uiSchemaVideoTranscoding}
                              ></JsonSchemaForm>
                            ) : (
                              <Typography variant="h6">Loading...</Typography>
                            )}
                          </Box>
                        </>
                      ) : null}
                      {activeStep === 3 ? (
                        <>
                          <ReviewCreateJob
                            optionsPerformance={optionsPerformance}
                            input={jobDataForm}
                            template={jobParams?.preset}
                            outputs={jobConfigForm}
                            jobCostEstimation={jobCostEstimation}
                            fileIsUploading={fileIsUploading}
                            availableBalance={balance}
                            errorEstimation={errorEstimation}
                            presets={jobPresets}
                          ></ReviewCreateJob>
                        </>
                      ) : null}
                    </>
                  )}
                </Box>
              </div>
            </Box>
          </div>
        </div>
      </Paper>
      <Box textAlign={"center"} mt={3} mb={3}>
        {activeStep > 0 ? (
          <PreviousButton color={"primary"} variant={"outlined"} />
        ) : (
          ""
        )}
        {activeStep < 3 ? (
          <NextButton
            style={{ marginLeft: "2em" }}
            color={"primary"}
            variant={"contained"}
          />
        ) : (
          ""
        )}
        {activeStep === 3 ? (
          <StartButton
            style={{ marginLeft: "2em" }}
            color={"primary"}
            variant={"contained"}
          />
        ) : (
          ""
        )}
      </Box>
    </>
  );

  return (
    <Box>
      <div className="page-header">
        <div className="new-job-header">
          <Typography variant={"h2"} className="color-blue1">
            Start new job
          </Typography>
          {activeStep < 3 && (
            <TextField
              placeholder={"Give a name to this job"}
              label={"Name this job"}
              color={"secondary"}
              value={jobName}
              required={true}
              error={
                liveValidateParams || liveValidateDataSource
                  ? jobName === ""
                  : false
              }
              onChange={(ev) => setJobName(ev.target.value)}
            ></TextField>
          )}
        </div>
        {activeStep >= 0 && (
          <div>
            <Button
              style={{ width: "100%" }}
              variant="text"
              color="primary"
              size="large"
              onClick={() => setShowJson(!showJson)}
            >
              {showJson ? "Switch to UI" : "{} Switch to JSON"}
            </Button>
          </div>
        )}
      </div>
      {showJson ? (
        <Paper elevation={3}>
          <div className={"container-vertical content-start"}>
            <div className={"container-horizontal content-center"}>
              <Box sx={{ my: "3em", width: "80%" }}>
                <Typography variant={"h2"} className="color-blue1">
                  Your JSON
                </Typography>
                <pre className="json-preview">
                  {JSON.stringify(currentJobJson(), null, "  ")}
                </pre>
                <Box mt={2} display="flex" flexDirection="rows">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={copyJson}
                  >
                    Copy
                  </Button>
                </Box>
              </Box>
            </div>
          </div>
        </Paper>
      ) : (
        <Wizard />
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={notification.open}
        autoHideDuration={3000}
        onClose={hideNotification}
        message={notification.text}
      />
    </Box>
  );
};

export default withApi(withAuth(withStyles(styles)(JobEditor)));
