import React, { useEffect, useState } from "react";
import { useAuthContext } from "@bytenite/auth/src/hoc/Auth/context";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios, { AxiosError } from "axios";

type CallbackPageProps = {};
const CallbackPage: React.FC<CallbackPageProps> = ({}) => {
    const queryParams = new URLSearchParams(location.search);
    const authCode = queryParams.get("code");
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const authCtx = useAuthContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (authCode && !isLoading) {
            authCtx
                .exchangeCodeForToken(authCode)
                .then((resp) => {
                    navigate("/jobs");
                })
                .catch((err) => {
                    console.error("TOKEN ERROR", err);
                    if (axios.isAxiosError(err)) {
                        console.error(
                            "Axios ERROR",
                            (err as AxiosError).toJSON()
                        );
                    }
                    setError(JSON.stringify(err));
                });
        } else if (isLoading) {
            setLoading(false);
        }
    }, [authCode, isLoading]);

    if (isLoading) {
        return <></>;
    }

    //
    if (!error) {
    }

    return (
        <>
            {error && (
                <Box>
                    <Typography variant="h2">An error occurred</Typography>
                    <Typography variant="body1" color="error">
                        {error}
                    </Typography>
                </Box>
            )}
        </>
    );
};

export default CallbackPage;
