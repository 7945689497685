import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import PageHeader from "@bytenite/components/src/components/layoutComponents/PageHeader";
import "@bytenite/components/src/styles/sass/containers.scss";
import JobHistory from "@bytenite/components/src/components/displayDataComponents/JobHistory";
import { withApi } from "../hoc/ApiProvider/Provider";
import { useNavigate } from "react-router-dom";
import AuthContext from "@bytenite/auth/src/hoc/Auth/context";
import { CommonFilterCondition, JobJobState } from "../client";
import apiService from "../services/ApiService";

export const POLLING_INTERVAL = 30000;

//function taken from https://blog.bitsrc.io/polling-in-react-using-the-useinterval-custom-hook-e2bcefda4197
export function useInterval(callback, delay) {
    const savedCallback = useRef();
    // Remember the latest callback
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => {
                clearInterval(id);
            };
        }
    }, [callback, delay]);
}

const Encoding = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [jobsHistoryFilters, setJobsHistoryFilters] = useState({
        limit: props.api.getDefaultLimitFilters(),
        offset: 0,
        filters: [
            props.api.getJobStateFilters([], CommonFilterCondition.IN, true),
        ],
    });
    const [jobsQueueFilters, setJobsQueueFilters] = useState({
        limit: props.api.getDefaultLimitFilters(),
        offset: 0,
        filters: [
            props.api.getJobStateFilters([], CommonFilterCondition.IN, false),
        ],
    });
    const [jobsHistory, setJobsHistory] = useState({});
    const [jobsQueue, setJobsQueue] = useState({});
    const [runningJobsTasks, setRunningJobsTasks] = useState({});
    const [pollingJobsTasksActive, setPollingJobsTasksActive] = useState(true);

    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);

    const createNewJob = () => {
        setIsLoading(true);
        props.api
            .createNewJob()
            .then((newJob) => {
                setIsLoading(false);
                if (newJob && newJob.id) {
                    navigate(`/jobs/${newJob.id}`);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                if (err.message.endsWith("auth.not_authenticated")) {
                    navigate(authCtx.loginUrl);
                }
            });
    };

    const loadJobsHistory = (api) => {
        return api
            .getJobsFiltered(jobsHistoryFilters)
            .then((resp) => {
                setIsLoading(false);
                setJobsHistory(resp);
            })
            .catch((err) => {
                console.error(err);
                setIsLoading(false);
                setJobsHistory([]);
            });
    };

    const loadQueueHistory = (api) => {
        api.getJobsFiltered(jobsQueueFilters)
            .then((resp) => {
                setIsLoading(false);
                setJobsQueue(resp);
            })
            .catch((err) => {
                console.error(err);
                setIsLoading(false);
                setJobsQueue([]);
            });
    };

    const abortJob = (api, jobId) => {
        setIsLoading(true);
        api.abortJob(jobId).then(() => {
            loadJobsHistory(api);
            loadQueueHistory(api);
        });
    };

    const copyJob = (api, jobId) => {
        setIsLoading(true);
        api.copyJob(jobId).then(() => {
            loadQueueHistory(api);
        });
    };

    const deleteJob = (api, jobId) => {
        setIsLoading(true);
        api.deleteJob(jobId).then(() => {
            loadJobsHistory(api);
            loadQueueHistory(api);
        });
    };

    const downloadResults = (api, jobId) => {
        api.getJobResults(jobId).then((results) => {
            results.forEach((result) => {
                let a = document.createElement("a");
                a.href = result.link;
                a.target = "_blank";
                a.download = result.name;
                a.click();
            });
        });
    };

    const canAbort = (jobState) => {
        return (
            [
                JobJobState.UPLOADING,
                JobJobState.PARTITIONING,
                JobJobState.PARTITIONED,
            ].indexOf(jobState) !== -1
        );
    };
    const canDelete = (jobState) => {
        return (
            [JobJobState.NEW, JobJobState.FAILED, JobJobState.COMPLETE].indexOf(
                jobState
            ) !== -1
        );
    };
    const canEdit = (jobState) => {
        return [JobJobState.NEW].indexOf(jobState) !== -1;
    };
    const isComplete = (jobState) => {
        return jobState === JobJobState.COMPLETE;
    };

    const addFilterPageNumHistory = (pageNum) => {
        let filters = jobsHistoryFilters;
        filters.offset = (pageNum - 1) * filters.limit;
        setJobsHistoryFilters(JSON.parse(JSON.stringify(filters)));
    };

    const addFilterPageNumQueue = (pageNum) => {
        let filters = jobsQueueFilters;
        filters.offset = (pageNum - 1) * filters.limit;
        setJobsQueueFilters(JSON.parse(JSON.stringify(filters)));
    };

    const calculateOrderByFilter = (columnName, previousOrderBy) => {
        if (previousOrderBy && previousOrderBy !== "") {
            const previousColumn = previousOrderBy
                .replace("ASC", "")
                .replace("DESC", "")
                .trim();
            let order = previousOrderBy.replace(previousColumn, "").trim();
            if (previousColumn === columnName) {
                if (order === "ASC") {
                    order = "DESC";
                } else {
                    order = "ASC";
                }
            } else {
                order = "ASC";
            }
            return columnName + " " + order;
        } else {
            return columnName + " ASC";
        }
    };

    const addFilterOrderHistory = (columnName) => {
        let filters = jobsHistoryFilters;
        filters.offset = 0; //restart from first page
        filters.orderBy = calculateOrderByFilter(columnName, filters.orderBy);
        setJobsHistoryFilters(JSON.parse(JSON.stringify(filters)));
    };

    const addFilterOrderQueue = (columnName) => {
        let filters = jobsQueueFilters;
        filters.offset = 0; //restart from first page
        filters.orderBy = calculateOrderByFilter(columnName, filters.orderBy);
        setJobsQueueFilters(JSON.parse(JSON.stringify(filters)));
    };

    const setJobNameQueue = (jobName) => {
        jobsQueueFilters.offset = 0; //restart from first page
        jobsQueueFilters.filters = jobsQueueFilters.filters.filter(
            (f) => f.field !== "name"
        );
        if (jobName && jobName.length > 0) {
            jobsQueueFilters.filters.push(
                props.api.createJobFilter(
                    jobName.toLowerCase(),
                    "name",
                    CommonFilterCondition.LIKE
                )
            );
        }
        setJobsQueueFilters(JSON.parse(JSON.stringify(jobsQueueFilters)));
    };

    const setJobNameHistory = (jobName) => {
        jobsHistoryFilters.offset = 0; //restart from first page
        jobsHistoryFilters.filters = jobsHistoryFilters.filters.filter(
            (f) => f.field !== "name"
        );
        if (jobName && jobName.length > 0) {
            jobsHistoryFilters.filters.push(
                props.api.createJobFilter(
                    jobName.toLowerCase(),
                    "name",
                    CommonFilterCondition.LIKE
                )
            );
        }
        setJobsHistoryFilters(JSON.parse(JSON.stringify(jobsHistoryFilters)));
    };

    const updateHistoryJobs = () => {
        props.api
            .getJobsFiltered(jobsHistoryFilters)
            .then((resp) => {
                setJobsHistory(resp);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const updateQueueJobs = () => {
        if (pollingJobsTasksActive) {
            props.api
                .getJobsFiltered(jobsQueueFilters)
                .then((resp) => {
                    setJobsQueue(resp);
                    const running = resp.data
                        .filter((x) => x.state !== JobJobState.NEW)
                        .map((j) => j.id);
                    if (running.length > 0) {
                        props.api.getJobsTasks(running).then((resp) => {
                            setRunningJobsTasks(resp.jobsRunningTasks);
                            //if a job completed or failed we move from the queue to the history
                            updateHistoryJobs();
                        });
                    } else {
                        updateHistoryJobs(); //last update of history
                        setPollingJobsTasksActive(false);
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    //check every 30 seconds if the completed
    useInterval(() => {
        //updateQueueJobs()
    }, POLLING_INTERVAL);

    useEffect(() => {
        //setPollingJobsTasksActive(true)
        //updateQueueJobs()
    }, [jobsQueueFilters]);

    useEffect(() => {
        updateHistoryJobs();
    }, [jobsHistoryFilters]);

    useEffect(() => {
        updateQueueJobs();
        updateHistoryJobs();
    }, []);

    return (
        <Box>
            <PageHeader
                title={"Jobs"}
                createNewJob={createNewJob}
            ></PageHeader>
            <JobHistory
                jobsHistory={jobsHistory}
                jobsQueue={jobsQueue}
                isLoading={isLoading}
                setJobsHistoryFilters={setJobsHistoryFilters}
                setJobsQueueFilters={setJobsQueueFilters}
                runningJobsTasks={runningJobsTasks || {}}
                addFilterPageNumHistory={(pageNum) =>
                    addFilterPageNumHistory(pageNum)
                }
                addFilterPageNumQueue={(pageNum) =>
                    addFilterPageNumQueue(pageNum)
                }
                addFilterOrderHistory={(columnName) =>
                    addFilterOrderHistory(columnName)
                }
                addFilterOrderQueue={(columnName) =>
                    addFilterOrderQueue(columnName)
                }
                limitFilters={props.api.getDefaultLimitFilters()}
                abortJob={(jobId) => abortJob(props.api, jobId)}
                downloadResults={(jobId) => downloadResults(props.api, jobId)}
                copyJob={(jobId) => copyJob(props.api, jobId)}
                canAbort={canAbort}
                canEdit={canEdit}
                deleteJob={(jobId) => deleteJob(props.api, jobId)}
                isComplete={isComplete}
                canDelete={canDelete}
                onChangeJobNameQueue={(jobName) => setJobNameQueue(jobName)}
                onChangeJobNameHistory={(jobName) => setJobNameHistory(jobName)}
            ></JobHistory>
        </Box>
    );
};

export default withApi(Encoding);
