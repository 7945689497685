import { RouteDataItems } from "customer/src/types/misc";
import EncodingPage from "customer/src/pages/EncodingPage";
import CallbackPage from "customer/src/pages/CallbackPage";
import SigninPage from "auth/src/pages/signin-page/signin-page";
import LogoutPage from "../../pages/LogoutPage";
import DropboxAuthPage from "../../pages/DropboxAuthPage";
import RunJobLitePage from "customer/src/pages/RunJobLitePage";
import ConnectorsCallback from "customer/src/pages/ConnectorsCallback";
import ProfilePage from "customer/src/pages/ProfilePage";
import JobEditorPage from "customer/src/pages/JobEditorPage";
import WalletPage from "customer/src/pages/WalletPage";
import DocumentationPage from "customer/src/pages/DocumentationPage";
import SupportPage from "customer/src/pages/SupportPage";
import BillingPage from "customer/src/pages/BillingPage";

export const routeData: RouteDataItems[] = [
    {
        path: "/",
        element: EncodingPage,
        layout: true,
        private: true,
    },
    {
        path: "/jobs",
        element: EncodingPage,
        layout: true,
        private: true,
    },
    {
        path: "/callback",
        element: CallbackPage,
        layout: false,
        private: false,
    },
    {
        path: "/auth/login",
        element: SigninPage,
        layout: false,
        private: false,
    },
    {
        path: "/logout",
        element: LogoutPage,
        layout: false,
        private: false,
    },
    {
        path: "/connectors/dropbox",
        element: DropboxAuthPage,
        layout: false,
        private: false,
        showProfile: false,
        topBar: true,
    },
    {
        path: "/connectors_callback",
        element: ConnectorsCallback,
        layout: false,
        private: false,
        showProfile: false,
        topBar: true,
    },
    {
        path: "/connectors/:connector",
        element: RunJobLitePage,
        layout: false,
        private: false,
        showProfile: true,
        topBar: true,
    },
    {
        path: "/documentation",
        element: DocumentationPage,
        layout: true,
        private: true,
    },
    {
        path: "/help",
        element: SupportPage,
        layout: true,
        private: true,
    },
    {
        path: "/profile",
        element: ProfilePage,
        layout: true,
        private: true,
    },
    // {
    //     path: "/wallet",
    //     element: WalletPage,
    //     layout: true,
    //     private: true,
    // },
    {
        path: "/jobs/:id",
        element: JobEditorPage,
        layout: true,
        private: true,
    },
    {
        path: "/billing",
        element: BillingPage,
        layout: true,
        private: true,
    },
];
