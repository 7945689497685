import { Box } from "@mui/material";
import React from "react";
import DisplayDataPaper from "./_displayDataPaper";
import PaginationData from "./PaginationData";
import DisplayDataTable from "./_displayDataTable";
import { ReactComponent as FilmSimple } from "../../assets/jobIcons/film-simple.svg";
import { TransactionHistoryProps } from "./wallet/BillingOverview/types";

const TransactionHistory = (props: TransactionHistoryProps) => {
    const { data } = props;
    const columnName = [
        "Date & Time",
        "Description",
        "Amount",
        "Currency Amount",
        "Balance",
    ];
    const columnOrder = [
        "createdAt",
        "description",
        "amount",
        "relatedEntityData",
        "balance",
    ];
    return (
        <Box>
            <DisplayDataPaper
                title={"Transaction History"}
                children={
                    <DisplayDataTable
                        data={data}
                        columnsNames={columnName}
                        columnsOrder={columnOrder}
                        currentPageSize={0}
                        iconNoData={<FilmSimple />}
                        messageNoData="Nothing in the transaction history so far"
                    />
                }
            />
            <PaginationData
                limit={0}
                pagination={undefined}
                onPageNumberClick={undefined}
            />
        </Box>
    );
};

export default TransactionHistory;
