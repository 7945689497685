import React, { Component, memo, JSX } from "react";
import ApiContext, { ApiContextInterface } from "./context";
import { Alert, Box, CircularProgress, Snackbar } from "@mui/material";
import {
    BytenitecustomerServerInfoResponse,
    CommonFilterCondition,
    CommonGenericResponse,
    Configuration,
    CustomerJobsRunningTasksRequest,
    CustomerJobsRunningTasksResponse,
    CustomerQuery,
    CustomerSchemaResponse,
    DataSourceDataSource,
    JobAppParams,
    JobJob,
    JobsCreateJobRequest,
    JobsDataSourceInfoResponse,
    JobsDataSourceParams,
    JobsJobEstimationResponse,
    JobsJobPreset,
    JobsJobResult,
    JobsJobsResponse,
    StatsOverviewStatsResponse,
    UpdateSubscriptionRequestUpdateSubscriptionRequestBody as UpdateSubscriptionRequestBody,
    WalletCreateSubscriptionRequest,
    WalletCreateSubscriptionResponse,
    WalletExchangeRateResponse,
    WalletPlan,
    WalletSubscription,
    WalletTopUpResponse,
} from "../../client";
import { ApiService } from "../../services/ApiService";
import { withAuth } from "@bytenite/auth/src/hoc/Auth/Provider";
import {
    AuthContextInterface,
    useAuthContext,
    User,
} from "@bytenite/auth/src/hoc/Auth/context";
import axios, { AxiosProgressEvent } from "axios";
// @ts-ignore
import { EventSourcePolyfill } from "event-source-polyfill";

export interface ApiProviderProps {
    auth: AuthContextInterface<User>;
    apiConfig: Configuration;
    serverUrl?: string;
}

export interface Error {
    code: number;
    message: string;
    details: Array<string>;
}

type OptionsFlags<Type> = {
    [Property in keyof Type]: boolean;
};

export type ServerEvent = {
    result: {
        event: string;
        data: {
            "@type": string;
            value: object;
        };
    };
};

type JobUpload = {
    job: JobJob;
    promise: Promise<any>;
    progress: number;
};

type ApiProviderState = {
    errorMessage?: string;
    creatingJob?: boolean;
    isLoading: boolean;
    pendingUploads: Map<string, JobUpload>;
};

const DEFAULT_TEMPLATE_ID = "video-transcoding@v0.2";
const DEFAULT_SCHEMA_ID = "video-transcoding";

export const EVENT_JOB_UPLOAD_PROGRESS = "jobUpload:progress";
export const EVENT_JOB_UPLOAD_COMPLETED = "jobUpload:completed";

export const EVENT_WALLET_TOPUP = "accounts:top_up";
export const EVENT_WALLET_SUBSCRIPTION = "accounts:subscription";

//const CACHE_PENDING_JOB = 'pending-job';

class ApiProvider extends Component<
    React.PropsWithChildren<ApiProviderProps>,
    ApiProviderState
> {
    private apiService: ApiService;
    private schemaCache: Map<string, CustomerSchemaResponse>;
    private eventSource: EventSourcePolyfill;

    constructor(props: ApiProviderProps) {
        super(props);
        this.state = {
            isLoading: true,
            creatingJob: false,
            pendingUploads: new Map<string, JobUpload>(),
        };
        this.schemaCache = new Map<string, CustomerSchemaResponse>();
        const auth = this.props.auth;
        const apiConfig = Object.assign({}, props).apiConfig;

        /*
         */
        /*this.apiService = new ApiService({
      ...apiConfig,
      get apiKey() {
        console.log('get api key', auth.getCachedToken())
        return auth.getCachedToken()
      }
    })*/
    }

    tokenUpdateCallback = () => {
        const auth = this.props.auth;
        const apiConfig = Object.assign({}, this.props).apiConfig;
        this.apiService = new ApiService({
            ...apiConfig,
            get apiKey() {
                return auth.getCachedToken();
            },
        });
    };

    componentDidMount() {
        console.debug("* API PROVIDER MOUNT *");
        const auth = this.props.auth;
        const apiConfig = Object.assign({}, this.props).apiConfig;
        this.apiService = new ApiService({
            ...apiConfig,
            get apiKey() {
                return auth.getCachedToken();
            },
        });
        auth.onTokenUpdate(this.tokenUpdateCallback);
        this.setState({ isLoading: false });
        //this.apiService.eventsApi().customerEvents({headers: {'Content-Type': 'text/event-stream; charset=utf-8'}})

        const eventsUrl = `${apiConfig.basePath}/customer/events`;
        this.eventSource = new EventSourcePolyfill(eventsUrl, {
            headers: {
                Authorization: auth.getCachedToken(),
            },
        });
        this.eventSource.onmessage = (e: { data: string }) => {
            const event = JSON.parse(e.data) as ServerEvent;
            const splEvent = event.result.event.split(".");
            if (splEvent.length > 3) {
                const localEventName = `${splEvent[1]}:${splEvent[2]}`;
                const localEvent = new CustomEvent(localEventName, {
                    detail: event.result.data.value,
                });
                document.dispatchEvent(localEvent);
            }
        };
    }

    componentWillUnmount() {
        console.debug("* API PROVIDER UNMOUNT *");
        const auth = this.props.auth;
        if (auth) {
            auth.removeTokenUpdateListener(this.tokenUpdateCallback);
        }
        if (this.eventSource) {
            this.eventSource.close();
        }
    }

    get serverUrl() {
        return this.props.serverUrl;
    }

    errorHandler(err: any) {
        if (err instanceof Response) {
            const contentType = err.headers.get("Content-Type");
            try {
                if (contentType && contentType.indexOf("json") === -1) {
                    return err.json().then((errResp: Error) => {
                        this.setState({ errorMessage: errResp.message });
                        throw errResp;
                    });
                } else {
                    return err.text().then((errResp: string) => {
                        this.setState({ errorMessage: errResp });
                        throw { code: err.status, message: errResp };
                    });
                }
            } catch (e) {
                throw { code: err.status, message: e.message };
            }
        } else {
            throw err;
        }
    }

    setCreatingJob(val: boolean, callbackSetState: () => void): void {
        this.setState(
            {
                creatingJob: val,
            },
            () => callbackSetState()
        );
    }

    getCreatingJob(): boolean {
        return this.state.creatingJob;
    }

    serverInfo() {
        return this.apiService.serverInfo(); // {headers: {'Authorization': token}}
    }

    getSchema(id: string) {
        const cachedVal: any = null; //this.schemaCache.get(id) // TODO: disabled for tests
        if (cachedVal) {
            return new Promise<CustomerSchemaResponse>((resolve) =>
                resolve(cachedVal)
            );
        }
        return this.apiService
            .getSchema(id)
            .then((resp: CustomerSchemaResponse) => {
                // TODO: disabled for tests
                // this.schemaCache.set(id, resp)
                return resp;
            })
            .catch((err) => this.errorHandler(err));
    }

    async getJobById(jobId: string): Promise<JobJob> {
        const token = await this.props.auth.getToken();
        return this.apiService
            .jobsApi()
            .customerGetJob(jobId, { headers: { Authorization: token } })
            .then((resp) => {
                return resp.job;
            })
            .catch((err) => this.errorHandler(err));
    }

    getJobs(limit = 20, offset = 0): Promise<Array<JobJob>> {
        return this.apiService
            .jobsApi()
            .customerGetAll(limit, offset)
            .then((resp) => resp.data)
            .catch((err) => this.errorHandler(err));
    }

    getJobsFiltered(jobsHistoryFilters: any): Promise<JobsJobsResponse> {
        const query: CustomerQuery = {
            pagination: {
                limit: jobsHistoryFilters.limit,
                offset: jobsHistoryFilters.offset,
            },
            filters: jobsHistoryFilters.filters,
            orderBy: jobsHistoryFilters.orderBy,
        };
        return this.apiService
            .jobsApi()
            .customerGetAllFiltered(query)
            .then((resp) => {
                resp.data = resp.data.map((j) => {
                    // @ts-ignore
                    j.hasPendingUpload = this.hasPendingUpload(j.id);
                    return j;
                });
                return resp;
            })
            .catch((err) => this.errorHandler(err));
    }

    getJobStateFilters(
        states: string[],
        condition: CommonFilterCondition,
        isHistory: boolean
    ) {
        if (isHistory) {
            return this.apiService.getHistoryJobStateFilters(states, condition);
        } else {
            return this.apiService.getQueueJobStateFilters(states, condition);
        }
    }

    createJobFilter(
        value: any,
        field: string,
        condition: CommonFilterCondition
    ) {
        return this.apiService.createJobFilter(value, field, condition);
    }

    getDefaultLimitFilters() {
        return this.apiService.getDefaultLimitFilters();
    }

    async saveJobParameters(
        job: JobJob,
        jobParams: JobAppParams
    ): Promise<JobJob> {
        // const body: JobAppParams = this.apiService.getJobAppParams(selectedPreset, data, schema);
        if (
            jobParams.data &&
            typeof jobParams.data === "object" &&
            Object.keys(jobParams.data).length === 0
        ) {
            jobParams.data = null;
        }

        const token = await this.props.auth.getToken();

        return this.apiService
            .jobsApi()
            .customerSetJobParams(job.id, jobParams, {
                headers: { Authorization: token },
            })
            .then((resp) => {
                //localStorage.setItem(CACHE_PENDING_JOB, JSON.stringify(resp.job))
                return resp?.job;
            })
            .catch((err) => this.errorHandler(err));
    }

    getHtmlFormDataFromDataSource(job: JobJob): any {
        return this.apiService.getHtmlFormDataFromDataSource(job);
    }

    async saveJobDataSource(
        job: JobJob,
        source: any,
        destination: any
    ): Promise<JobJob> {
        //source_type
        //const dataSourceDataSource = this.apiService.getDataSourceDataSource(source.source_type, source)
        //const dataDestinationDataSource = this.apiService.getDataSourceDataSource(destination.destination_type, destination)

        const body: JobsDataSourceParams =
            this.apiService.getJobsDataSourceParams(source, destination);

        const token = await this.props.auth.getToken();

        return this.apiService
            .jobsApi()
            .customerSetJobDataSource(job.id, body, {
                headers: { Authorization: token },
            })
            .then((resp) => {
                //localStorage.setItem(CACHE_PENDING_JOB, JSON.stringify(resp.job))
                return resp.job;
            })
            .catch((err) => this.errorHandler(err));
    }

    async getDataSourceInfo(
        dataSource?: DataSourceDataSource,
        listFiles?: boolean
    ): Promise<JobsDataSourceInfoResponse> {
        const token = await this.props.auth.getToken();

        return this.apiService
            .jobsApi()
            .customerDataSourceInfo(
                { dataSource, listFiles: listFiles },
                { headers: { Authorization: token } }
            )
            .then((resp) => {
                //localStorage.setItem(CACHE_PENDING_JOB, JSON.stringify(resp.job))
                return resp;
            })
            .catch((err) => this.errorHandler(err));
    }

    async saveJobName(job: JobJob, jobName: string): Promise<boolean> {
        const token = await this.props.auth.getToken();

        return this.apiService
            .jobsApi()
            .customerSetJobName(job.id, jobName, {
                headers: { Authorization: token },
            })
            .then((resp) => {
                return !!resp.job;
            })
            .catch((err) => this.errorHandler(err));
    }

    async getJobEstimation(jobId: string): Promise<JobsJobEstimationResponse> {
        const token = await this.props.auth.getToken();

        return this.apiService
            .jobsApi()
            .customerGetJobEstimation(jobId, {
                headers: { Authorization: token },
            })
            .then((resp) => {
                return resp;
            })
            .catch((err) => this.errorHandler(err));
    }

    async setLocalFileUploaded(jobId: string): Promise<CommonGenericResponse> {
        const token = await this.props.auth.getToken();

        return this.apiService
            .jobsApi()
            .customerSetUploadCompleted(jobId, {
                headers: { Authorization: token },
            })
            .then((resp) => {
                return resp;
            })
            .catch((err) => this.errorHandler(err));
    }

    async getJobsTasks(
        jobsIds: string[]
    ): Promise<CustomerJobsRunningTasksResponse> {
        const token = await this.props.auth.getToken();
        const body: CustomerJobsRunningTasksRequest = { jobsIds };
        return this.apiService
            .jobsApi()
            .customerGetJobsRunningTasks(body, {
                headers: { Authorization: token },
            })
            .then((resp) => {
                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    uploadJobFile(job: JobJob, selectedFile: File): Promise<void> {
        const api = this.apiService;
        if (job.dataSource.dataSourceDescriptor !== "file") {
            throw "invalid data source descriptor";
        }
        const pendingUploads = this.state.pendingUploads;
        if (this.hasPendingUpload(job.id)) {
            return new Promise((resolve) => resolve(null));
        }

        const urlUpload = job.dataSource.params.tempUrl;
        const promise = axios
            .request({
                method: "PUT",
                url: urlUpload,
                //headers: { 'Content-Type' : 'multipart/form-data'},
                headers: {
                    "Content-Disposition": `attachment; filename="${selectedFile.name}"`,
                    "Content-Type": "application/json",
                },
                data: selectedFile,
                onUploadProgress: (p) => {
                    const pendingUpload = pendingUploads.get(job.id);
                    if (pendingUpload) {
                        pendingUpload.progress = (p.loaded / p.total) * 100;
                    }
                    this.notifyUploadProgress(job.id, p);
                },
            })
            .then((resp) => {
                return this.setLocalFileUploaded(job.id)
                    .then((resp) => {
                        this.uploadCompleted(job.id, true);
                    })
                    .catch((err) => {
                        throw err;
                    });
            })
            .catch((err) => {
                this.uploadCompleted(job.id, false, err);
                //throw err
            });
        pendingUploads.set(job.id, { job, promise, progress: 0 });
        this.setState({ ...this.state, pendingUploads });
        return promise;
    }

    private notifyUploadProgress(jobId: string, progress: AxiosProgressEvent) {
        const event = new CustomEvent(EVENT_JOB_UPLOAD_PROGRESS, {
            detail: { id: jobId, progress },
        });
        document.dispatchEvent(event);
    }

    private uploadCompleted(jobId: string, success: boolean, error?: any) {
        const pendingUploads = this.state.pendingUploads;
        if (pendingUploads.delete(jobId)) {
            this.setState({ ...this.state, pendingUploads }, () => {
                const event = new CustomEvent(EVENT_JOB_UPLOAD_COMPLETED, {
                    detail: { id: jobId, success: success, error: error },
                });
                document.dispatchEvent(event);
            });
        }
    }

    pendingUploadProgress(jobId: string) {
        const pendingUpload = this.state.pendingUploads.get(jobId);
        if (pendingUpload) {
            return pendingUpload.progress;
        }
        return -1;
    }

    hasPendingUpload(jobId: string) {
        return this.state.pendingUploads.has(jobId);
    }

    cancelUpload(jobId: string) {
        if (!this.hasPendingUpload(jobId)) {
            return false;
        }

        return false;
    }

    private getJobPresets(jobTemplateId: string) {
        return this.apiService
            .jobsApi()
            .customerGetJobPresets(jobTemplateId)
            .then((resp) => resp.presets);
    }

    private get defaultTemplateId() {
        return DEFAULT_TEMPLATE_ID;
    }

    private get defaultSchemaId() {
        return DEFAULT_SCHEMA_ID;
    }

    private async runJob(jobId: string) {
        const token = await this.props.auth.getToken();
        return this.apiService
            .jobsApi()
            .customerRunJob(jobId, {}, { headers: { Authorization: token } })
            .then((runJobResponse) => {
                //localStorage.removeItem(CACHE_PENDING_JOB)
                return runJobResponse;
            })
            .catch((err) => this.errorHandler(err));
    }

    private abortJob(jobId: string): Promise<CommonGenericResponse> {
        return this.apiService
            .jobsApi()
            .customerAbortJob(jobId)
            .catch((err) => this.errorHandler(err));
    }

    private deleteJob(jobId: string): Promise<CommonGenericResponse> {
        return this.apiService
            .jobsApi()
            .customerDeleteJob(jobId)
            .catch((err) => this.errorHandler(err));
    }

    async createNewJob(
        templateId?: string,
        dataSource?: DataSourceDataSource,
        dataDestination?: DataSourceDataSource,
        params?: JobAppParams,
        name?: string
    ): Promise<JobJob> {
        const body: JobsCreateJobRequest = {
            templateId: templateId || this.defaultTemplateId,
            description: "Dev",
            name: name || "Job Name",
            dataSource: dataSource,
            dataDestination: dataDestination,
            params: params,
        };
        const token = await this.props.auth.getToken();
        return this.apiService
            .jobsApi()
            .customerCreateJob(body, { headers: { Authorization: token } })
            .then((newJobResponse) => {
                //localStorage.setItem(CACHE_PENDING_JOB, JSON.stringify(newJobResponse.job))
                return newJobResponse.job;
            })
            .catch((err) => this.errorHandler(err));
    }

    private copyJob(jobId: string): Promise<JobJob> {
        return this.getJobById(jobId)
            .then((jobToCopy) => {
                return this.createNewJob(jobToCopy.templateId).then(
                    (newJob) => {
                        const dataSourceParams =
                            this.apiService.getJobsDataSourceParams(
                                { ...(jobToCopy.dataSource || {}) },
                                { ...(jobToCopy.dataDestination || {}) }
                            );
                        return this.apiService
                            .jobsApi()
                            .customerSetJobDataSource(
                                newJob.id,
                                dataSourceParams
                            )
                            .then((resp) => {
                                if (jobToCopy.params) {
                                    return this.saveJobParameters(
                                        newJob,
                                        jobToCopy.params || {}
                                    ).then((resp) => {
                                        return resp || newJob;
                                    });
                                } else {
                                    return resp.job;
                                }
                            });
                    }
                );
            })
            .catch((err) => this.errorHandler(err));
    }

    private getJobResults(jobId: string): Promise<JobsJobResult[]> {
        return this.apiService
            .jobsApi()
            .customerGetJobResults(jobId)
            .then((resp) => resp.results)
            .catch((err) => this.errorHandler(err));
    }

    private getStatsOverview(
        startDate: Date,
        endDate: Date
    ): Promise<StatsOverviewStatsResponse> {
        return this.apiService
            .statsApi()
            .customerGetOverviewStats({
                startDate: startDate,
                endDate: endDate,
            })
            .then((resp) => {
                return resp;
            })
            .catch((err) => {
                return err;
            });
    }

    private async getPlans() {
        const plansResp = await this.apiService
            .walletApi()
            .customerGetPlans({});
        plansResp.plans?.sort(
            (a, b) => parseInt(a.amount) - parseInt(b.amount)
        );
        return plansResp.plans || [];
    }

    private async createSubscription(
        body: WalletCreateSubscriptionRequest
    ): Promise<WalletCreateSubscriptionResponse> {
        return await this.apiService
            .walletApi()
            .customerCreateSubscription(body, {});
    }
    private async getActiveSubscription(): Promise<WalletSubscription> {
        return await this.apiService
            .walletApi()
            .customerGetActiveSubscription({});
    }
    private async updateSubscription(
        id: string,
        body: UpdateSubscriptionRequestBody
    ) {
        return await this.apiService
            .walletApi()
            .customerUpdateSubscription(id, body, {});
    }

    private async cancelSubscription(id: string) {
        return await this.apiService
            .walletApi()
            .customerCancelSubscription(id, {});
    }

    apiContext(): ApiContextInterface {
        return {
            getBalance: () =>
                this.apiService.walletApi().customerGetBalance({}),
            getTransactionList: () =>
                this.apiService.walletApi().customerGetTransactionList({}),
            createTopUpRequest: (currency: string, amount: Number) =>
                this.apiService.walletApi().customerCreateTopUpRequest({
                    currency,
                    amount: amount.toString(),
                }),
            cancelTopUpRequest: (
                topUpRequestId: string,
                cancelMessage: string
            ) =>
                this.apiService.walletApi().customerCancelTopUpRequest({
                    id: topUpRequestId,
                    cancelMessage: cancelMessage,
                }),
            getExchangeRate: (
                currency: string,
                currencyAmount: Number,
                amount: Number
            ) =>
                this.apiService
                    .walletApi()
                    .customerGetExchangeRate(
                        currency,
                        currencyAmount.toString(),
                        amount.toString()
                    ),
            redeemPromoCode: (code: string) =>
                this.apiService
                    .walletApi()
                    .customerRedeemCoupon({ couponCode: code }),
            copyJob: (jobId: string) => this.copyJob(jobId),
            serverInfo: () => this.serverInfo(),
            getSchema: (id: string) => this.getSchema(id),
            serverUrl: this.props.serverUrl,
            getJobs: (limit = 20, offset = 0) => this.getJobs(limit, offset),
            getJobsFiltered: (jobsHistoryFilters: any) =>
                this.getJobsFiltered(jobsHistoryFilters),
            getJobsTasks: (jobsIds: string[]) => this.getJobsTasks(jobsIds),
            getJobStateFilters: (
                states: string[],
                condition: CommonFilterCondition,
                isHistory: boolean
            ) => this.getJobStateFilters(states, condition, isHistory),
            createJobFilter: (
                value: any,
                field: string,
                condition: CommonFilterCondition
            ) => this.createJobFilter(value, field, condition),
            getDefaultLimitFilters: () => this.getDefaultLimitFilters(),
            saveJobDataSource: (job: JobJob, source: any, destination: any) =>
                this.saveJobDataSource(job, source, destination),
            getDataSourceInfo: (
                dataSource?: DataSourceDataSource,
                listFiles?: boolean
            ) => this.getDataSourceInfo(dataSource, listFiles),
            saveJobName: (job: JobJob, jobName: string) =>
                this.saveJobName(job, jobName),
            getJobEstimation: (jobId: string) => this.getJobEstimation(jobId),
            setLocalFileUploaded: (jobId: string) =>
                this.setLocalFileUploaded(jobId),
            createNewJob: (
                templateId?: string,
                dataSource?: DataSourceDataSource,
                dataDestination?: DataSourceDataSource,
                params?: JobAppParams,
                name?: string
            ) =>
                this.createNewJob(
                    templateId,
                    dataSource,
                    dataDestination,
                    params,
                    name
                ),
            saveJobParameters: (job: JobJob, jobParams: JobAppParams) =>
                this.saveJobParameters(job, jobParams),
            runJob: (jobId: string) => this.runJob(jobId),
            abortJob: (jobId: string) => this.abortJob(jobId),
            deleteJob: (jobId: string) => this.deleteJob(jobId),
            getHtmlFormDataFromDataSource: (job: JobJob) =>
                this.getHtmlFormDataFromDataSource(job),
            getJobById: (jobId: string) => this.getJobById(jobId),
            setCreatingJob: (val: boolean, callback: () => void) =>
                this.setCreatingJob(val, callback),
            getCreatingJob: () => this.getCreatingJob(),
            defaultTemplateId: () => this.defaultTemplateId,
            defaultSchemaId: () => this.defaultSchemaId,
            getJobResults: (jobId) => this.getJobResults(jobId),
            getStatsOverview: (startDate, endDate) =>
                this.getStatsOverview(startDate, endDate),
            uploadJobFile: (job, selectedFile) =>
                this.uploadJobFile(job, selectedFile),
            hasPendingUpload: (jobId: string) => this.hasPendingUpload(jobId),
            pendingUploadProgress: (jobId: string) =>
                this.pendingUploadProgress(jobId),
            cancelUpload: (jobId: string) => this.cancelUpload(jobId),
            getJobPresets: (jobTemplateId: string) =>
                this.getJobPresets(jobTemplateId),
            getPlans: () => this.getPlans(),
            getActiveSubscription: () => this.getActiveSubscription(),
            createSubscription: (body: WalletCreateSubscriptionRequest) =>
                this.createSubscription(body),
            updateSubscription: (
                id: string,
                body: UpdateSubscriptionRequestBody
            ) => this.updateSubscription(id, body),
            cancelSubscription: (id: string) => this.cancelSubscription(id),

            getBillingOverView: () =>
                this.apiService.walletApi().customerGetTransactionList(),
            getBillingPortal: () =>
                this.apiService.walletApi().customerGetBillingPortalLink(),
        };
    }

    closeErrorSnackbar(val: boolean) {
        this.setState({ errorMessage: null });
    }

    render() {
        if (this.state.isLoading) {
            return <></>;
        }
        // Do not remove the const declaration: it changes "this" behavior
        const apiContext = this.apiContext();
        return (
            <ApiContext.Provider value={apiContext}>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={!!this.state.errorMessage}
                    onClose={() => this.closeErrorSnackbar(false)}
                >
                    <Alert
                        onClose={() => this.closeErrorSnackbar(false)}
                        severity="error"
                        sx={{ width: "100%" }}
                    >
                        {this.state.errorMessage}
                    </Alert>
                </Snackbar>
                {this.props.children}
            </ApiContext.Provider>
        );
    }
}

export const withApi = (Component: React.ComponentType) => {
    return ({ ref, ...props }: React.ComponentProps<any>) => (
        <ApiContext.Consumer>
            {(api: ApiContextInterface) => {
                return <Component ref={ref} api={api} {...props} />;
            }}
        </ApiContext.Consumer>
    );
};

// @ts-ignore
const ApiProviderWithAuth: ApiProvider = withAuth(ApiProvider);
export default ApiProviderWithAuth;
