import React from "react";
import Icon, { SvgIconProps } from "./Icon";

const ArrowsRight: React.FC<Omit<SvgIconProps, "path">> = React.memo(
    (props) => {
        return (
            <Icon
                path="M11.0859 5.89844L6.96094 9.83594C6.67969 10.0547 6.41406 10.0547 6.16406 9.83594C5.94531 9.55469 5.94531 9.28906 6.16406 9.03906L9.28125 6.0625H1.3125C0.96875 6.03125 0.78125 5.84375 0.75 5.5C0.78125 5.15625 0.96875 4.96875 1.3125 4.9375H9.28125L6.1875 1.96094C5.95312 1.71094 5.94531 1.44531 6.16406 1.16406C6.41406 0.945313 6.67969 0.945313 6.96094 1.16406L11.0859 5.10156C11.1953 5.21094 11.25 5.34375 11.25 5.5C11.25 5.65625 11.1953 5.78906 11.0859 5.89844Z"
                {...props}
            />
        );
    }
);

export default ArrowsRight;
