import { Card, CardContent, Typography, Box, Button } from "@mui/material";

import React from "react";
import ArrowsRight from "../../../icons/ArrowsRight";

const PaymentInfo = ({ handleClick }: { handleClick: () => void }) => {
    return (
        <Card>
            <CardContent>
                <Typography
                    color="#000271"
                    fontSize="18px"
                    lineHeight="32px"
                    fontWeight={600}
                    fontFamily="Gilroy"
                >
                    Payment Info
                </Typography>
                <Box mt={1} p={2}>
                    <Typography
                        color={"#000000"}
                        fontWeight={400}
                        fontFamily={"Gilroy"}
                        lineHeight={"22px"}
                        fontSize={"14px"}
                    >
                        Manage payment methods, billing details, and invoice
                        history.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 2,
                        alignItems: "center",
                    }}
                >
                    <Button
                        onClick={handleClick}
                        sx={{
                            color: "#B027CE",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "22px",
                            fontFamily: "Gilroy",
                        }}
                    >
                        My Customer Portal
                    </Button>
                    <Box mt={2}>
                        <ArrowsRight color="primary" size="small" />
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default PaymentInfo;
