import React from 'react';
import Icon, {SvgIconProps} from "./Icon";


const Info: React.FC<Omit<SvgIconProps, 'path'>> = React.memo((props) => {
    return <Icon viewBox="0 0 13 13" path="M6.5 1.625C5.60938 1.625 4.79688 1.84375 4.0625 2.28125C3.32812 2.70312 2.73438 3.29687 2.28125 4.0625C1.84375 4.82812 1.625 5.64062 1.625 6.5C1.625 7.35938 1.84375 8.17188 2.28125 8.9375C2.73438 9.70312 3.32812 10.2969 4.0625 10.7188C4.79688 11.1562 5.60938 11.375 6.5 11.375C7.39062 11.375 8.20312 11.1562 8.9375 10.7188C9.67188 10.2969 10.2656 9.70312 10.7188 8.9375C11.1562 8.17188 11.375 7.35938 11.375 6.5C11.375 5.64062 11.1562 4.82812 10.7188 4.0625C10.2656 3.29687 9.67188 2.70312 8.9375 2.28125C8.20312 1.84375 7.39062 1.625 6.5 1.625ZM6.5 12.5C5.40625 12.4844 4.40625 12.2188 3.5 11.7031C2.59375 11.1719 1.85938 10.4375 1.29688 9.5C0.765625 8.54688 0.5 7.54688 0.5 6.5C0.5 5.45312 0.765625 4.45312 1.29688 3.5C1.85938 2.5625 2.59375 1.82813 3.5 1.29688C4.40625 0.78125 5.40625 0.515625 6.5 0.5C7.59375 0.515625 8.59375 0.78125 9.5 1.29688C10.4062 1.82813 11.1406 2.5625 11.7031 3.5C12.2344 4.45312 12.5 5.45312 12.5 6.5C12.5 7.54688 12.2344 8.54688 11.7031 9.5C11.1406 10.4375 10.4062 11.1719 9.5 11.7031C8.59375 12.2188 7.59375 12.4844 6.5 12.5ZM5.5625 8.375H6.125V6.875H5.5625C5.21875 6.84375 5.03125 6.65625 5 6.3125C5.03125 5.96875 5.21875 5.78125 5.5625 5.75H6.6875C7.03125 5.78125 7.21875 5.96875 7.25 6.3125V8.375H7.4375C7.78125 8.40625 7.96875 8.59375 8 8.9375C7.96875 9.28125 7.78125 9.46875 7.4375 9.5H5.5625C5.21875 9.46875 5.03125 9.28125 5 8.9375C5.03125 8.59375 5.21875 8.40625 5.5625 8.375ZM6.5 5C6.28125 5 6.10156 4.92969 5.96094 4.78906C5.82031 4.64844 5.75 4.46875 5.75 4.25C5.75 4.03125 5.82031 3.85156 5.96094 3.71094C6.10156 3.57031 6.28125 3.5 6.5 3.5C6.71875 3.5 6.89844 3.57031 7.03906 3.71094C7.17969 3.85156 7.25 4.03125 7.25 4.25C7.25 4.46875 7.17969 4.64844 7.03906 4.78906C6.89844 4.92969 6.71875 5 6.5 5Z"
                 {...props}/>
});

export default Info;
