import * as React from "react";
import DisplayDataPaper from "./_displayDataPaper";

import { ReactComponent as FileIcon } from "../../assets/jobIcons/file.svg";
import DisplayDataTable from "./_displayDataTable";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import { ReactComponent as FilmSimple } from "../../assets/jobIcons/film-simple.svg";
import { ReactComponent as SearchIcon } from "../../assets/jobIcons/magnifying-glass.svg";
import PaginationData from "./PaginationData";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";

const columnsOrderHistory = [
    "expandIcon",
    "name",
    "id",
    "created_at",
    "duration",
    "length",
    "cost",
    "jobState",
];
const columnsNamesHistory = [
    "",
    "Job name",
    "ID",
    "Created at",
    "Duration",
    "Length",
    "Cost",
    "Status",
];
const columnsSortableHistory = ["name", "created_at"];

const columnsOrderQueue = [
    "expandIcon",
    "name",
    "id",
    "created_at",
    "progress",
    "jobState",
];
const columnsNamesQueue = [
    "",
    "Job name",
    "ID",
    "Created at",
    "Progress",
    "Status",
];
const columnsSortableQueue = ["name", "created_at"];
const columnsProgress = ["progress"];

export interface JobHistoryRecord {
    name: string;
    id: string;
    created_at: string;
    started_at: string;
    completed_at: string;
    duration: string;
    length: string;
    size: number;
    cost: number;
    state: string;
    jobState: any;
    errorMessage?: string;
}

export interface JobQueueRecord {
    name: string;
    created_at: string;
    started_at: string;
    id: string;
    progress: number;
    state: string;
    jobState: any;
}

export interface JobHistoryPaperProps {
    jobsHistory: any;
    jobsQueue: any;
    isLoading: boolean;
    addFilterPageNumQueue: any;
    addFilterPageNumHistory: any;
    addFilterOrderQueue: any;
    addFilterOrderHistory: any;
    limitFilters: number;
    abortJob: any;
    copyJob: any;
    deleteJob: any;
    downloadResults: any;
    canAbort: any;
    canEdit: any;
    isComplete: any;
    canDelete: any;
    onChangeJobNameQueue: any;
    onChangeJobNameHistory: any;
    runningJobsTasks: any;
}

function formatDate(dateString: string) {
    return new Date(dateString).toLocaleDateString();
}

function formatFullDate(dateString: string) {
    return new Date(dateString).toLocaleString();
}

function calculateProgress(job: any, runningJobsTasks: any): number {
    const jobId = job.id;
    const remainingTasks =
        runningJobsTasks && runningJobsTasks[jobId] !== undefined
            ? +runningJobsTasks[jobId]
            : 0;
    const numChunks = job.numChunks ? +job.numChunks : 1;
    if (remainingTasks >= numChunks) {
        return 0;
    } else {
        //90 to not show the job completed because even if there are no more tasks
        //there is the assembly part
        return Math.min(
            90,
            Math.floor(((numChunks - remainingTasks) / numChunks) * 100)
        );
    }
}

function secondsToHhMmSs(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    const secs = Math.floor(seconds - hours * 3600 - minutes * 60);

    let timeString = "";
    if (hours > 0) {
        timeString += hours + "h ";
    }
    if (minutes > 0 || hours > 0) {
        timeString += minutes + "m ";
    }
    timeString += secs + "s";

    return timeString;
}

function mindToHhMm(mins: number) {
    const hours = Math.floor(mins / 60);
    const minutes = mins - hours * 60;

    let timeString = "";
    if (hours > 0) {
        timeString += hours + "h ";
    }
    if (minutes > 0 || hours > 0) {
        timeString += minutes + "m ";
    }

    return timeString;
}

const parseJobsToJobHistoryRecords = (jobs: any[]) => {
    const parsedJobHistory: JobHistoryRecord[] = jobs.map((j) => ({
        name: j.name,
        id: j.id,
        created_at: formatDate(j.createdAt),
        started_at: formatFullDate(j.startedAt),
        completed_at: formatFullDate(j.completedAt),
        duration:
            j.startedAt && j.completedAt
                ? secondsToHhMmSs(
                      (new Date(j.completedAt).getTime() -
                          new Date(j.startedAt).getTime()) /
                          1000
                  )
                : "0",
        length: mindToHhMm(+j.numUnitsEstimation),
        size: 0, // TODO
        cost: j.paymentAmount,
        state: j.state,
        jobState: j.state.replace("JOB_STATE_", ""),
        errorMessage: j.errorMessage,
    }));
    return parsedJobHistory;
};

const parseJobsToJobQueueRecords = (jobs: any[], runningJobsTasks: any) => {
    const parsedJobQueue: JobQueueRecord[] = jobs.map((j) => ({
        name: j.name,
        created_at: formatDate(j.createdAt),
        started_at: formatFullDate(j.startedAt),
        id: j.id,
        progress:
            j.state === "JOB_STATE_NEW"
                ? 0
                : calculateProgress(j, runningJobsTasks),
        state: j.state,
        jobState: j.hasPendingUpload
            ? "UPLOADING"
            : j.state.replace("JOB_STATE_", ""),
    }));
    return parsedJobQueue;
};

const JobHistoryPaper = ({ ...props }: JobHistoryPaperProps) => {
    const {
        jobsHistory,
        jobsQueue,
        addFilterPageNumHistory,
        addFilterPageNumQueue,
        addFilterOrderQueue,
        addFilterOrderHistory,
        runningJobsTasks,
    } = props;
    const {
        abortJob,
        copyJob,
        deleteJob,
        downloadResults,
        canAbort,
        canEdit,
        isComplete,
        canDelete,
        onChangeJobNameQueue,
        onChangeJobNameHistory,
    } = props;

    const [jobNameQueue, setJobNameQueue] = useState("");
    const [jobNameHistory, setJobNameHistory] = useState("");

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            onChangeJobNameQueue(jobNameQueue);
        }, 3000);

        return () => clearTimeout(delayDebounceFn);
    }, [jobNameQueue, onChangeJobNameQueue]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            onChangeJobNameHistory(jobNameHistory);
        }, 3000);

        return () => clearTimeout(delayDebounceFn);
    }, [jobNameHistory, onChangeJobNameHistory]);

    const JobNameFilter = (onChangeJobName: any) => (
        <Box sx={{ mx: 1 }}>
            <FormControl sx={{ m: 1 }} fullWidth>
                <OutlinedInput
                    startAdornment={
                        <Box sx={{ mx: 1 }}>
                            <SearchIcon></SearchIcon>
                        </Box>
                    }
                    color={"primary"}
                    autoComplete="off"
                    placeholder="Search job"
                    onChange={(event) => onChangeJobName(event.target.value)}
                />
            </FormControl>
        </Box>
    );

    const DisplayData = (
        data: any[],
        iconNoData: any,
        messageNoData: string,
        columnsNames: string[],
        columnsOrder: string[],
        columnsSortable: string[],
        onColumnClick: any,
        columnsProgress: string[]
    ) => (
        <DisplayDataTable
            data={data}
            columnsNames={columnsNames}
            columnsOrder={columnsOrder}
            columnsSortable={columnsSortable}
            abortJob={abortJob}
            copyJob={copyJob}
            deleteJob={deleteJob}
            downloadResults={downloadResults}
            canAbort={canAbort}
            canEdit={canEdit}
            canDelete={canDelete}
            isComplete={isComplete}
            hasJobActions={true}
            iconNoData={iconNoData}
            displayTableIfNoData={true}
            messageNoData={messageNoData}
            columnsJobState={["jobState"]}
            onColumnClick={onColumnClick}
            columnsProgress={columnsProgress}
        />
    );

    return (
        <Box>
            <DisplayDataPaper
                title={"Job Queue"}
                headerActions={JobNameFilter(setJobNameQueue)}
                children={DisplayData(
                    parseJobsToJobQueueRecords(
                        jobsQueue && jobsQueue.data ? jobsQueue.data : [],
                        runningJobsTasks
                    ),
                    <FilmSimple></FilmSimple>,
                    "Nothing in queue... start now",
                    columnsNamesQueue,
                    columnsOrderQueue,
                    columnsSortableQueue,
                    addFilterOrderQueue,
                    columnsProgress
                )}
            ></DisplayDataPaper>
            <PaginationData
                limit={props.limitFilters}
                pagination={jobsQueue.pagination}
                onPageNumberClick={addFilterPageNumQueue}
            ></PaginationData>
            <Divider
                className={"divider-vertical-paper-large-no-top-margin"}
            ></Divider>
            <DisplayDataPaper
                title={"Job history"}
                headerActions={JobNameFilter(setJobNameHistory)}
                children={DisplayData(
                    parseJobsToJobHistoryRecords(
                        jobsHistory && jobsHistory.data ? jobsHistory.data : []
                    ),
                    <FileIcon></FileIcon>,
                    "Nothing in the job history so far",
                    columnsNamesHistory,
                    columnsOrderHistory,
                    columnsSortableHistory,
                    addFilterOrderHistory,
                    []
                )}
            ></DisplayDataPaper>
            <Box my={1}>
                <PaginationData
                    limit={props.limitFilters}
                    pagination={jobsHistory.pagination}
                    onPageNumberClick={addFilterPageNumHistory}
                ></PaginationData>
            </Box>
        </Box>
    );
};

export default JobHistoryPaper;
