import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ByteChip from "../../../icons/ByteChip";
import Button from "@mui/material/Button";
import AccountBalanceTooltip from "./AccountBalanceTooltip";

const AccountBalance = (props: { balance: number; amountDue: number }) => {
    const { balance, amountDue } = props;

    const dueDate: string = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        1
    ).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
    });

    return (
        <Card sx={{ maxHeight: "286px" }}>
            <CardContent>
                <Typography
                    color="#000271"
                    fontSize="18px"
                    lineHeight="32px"
                    fontWeight={600}
                    fontFamily="Gilroy"
                >
                    Account Balance
                </Typography>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={2}
                    gap={2}
                >
                    <Card
                        sx={{
                            width: "100%",
                            height: "170px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 1,
                        }}
                    >
                        <Typography
                            color="#000000"
                            fontSize="16px"
                            lineHeight="24px"
                            fontWeight={500}
                            fontFamily="Gilroy"
                        >
                            Current Balance
                        </Typography>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <Typography color="#000271" variant="h1">
                                {balance < 0 ? `-` : ``}
                            </Typography>
                            <ByteChip
                                color="secondary"
                                colorVariant="dark"
                                size="medium"
                            />
                            <Typography color="#000271" variant="h1">
                                {Math.abs(balance)}
                            </Typography>
                        </Stack>
                        <Typography
                            color="#000000"
                            fontSize="10px"
                            lineHeight="16px"
                            fontWeight={500}
                            fontFamily="Gilroy"
                        >
                            ByteChips
                        </Typography>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={
                                <ByteChip color="primary" size="small" />
                            }
                        >
                            Top Up
                        </Button>
                    </Card>
                    <Card
                        sx={{
                            width: "100%",
                            height: "170px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 1,
                        }}
                    >
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <Typography
                                color="#000000"
                                fontSize="16px"
                                lineHeight="24px"
                                fontWeight={500}
                                fontFamily="Gilroy"
                            >
                                Amount Due
                            </Typography>
                            <AccountBalanceTooltip />
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <Typography color="#000271" variant="h1">
                                {balance < 0 ? `-` : ``}
                            </Typography>
                            <Typography color="#000271" variant="h1">
                                ${Math.abs(amountDue)}
                            </Typography>
                        </Stack>
                        <Typography
                            color="#000000"
                            fontSize="10px"
                            lineHeight="16px"
                            fontWeight={500}
                            fontFamily="Gilroy"
                        >
                            due on
                        </Typography>
                        <Typography
                            color="#000000"
                            fontSize="16px"
                            lineHeight="24px"
                            fontWeight={500}
                            fontFamily="Gilroy"
                        >
                            {dueDate}
                        </Typography>
                    </Card>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default AccountBalance;
