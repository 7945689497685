import React from "react";
import { Grid, Skeleton } from "@mui/material";
import DisplayDataTable from "components/src/components/displayDataComponents/_displayDataTable";
import BillingOverview from "components/src/components/displayDataComponents/wallet/BillingOverview/BillingOverview";
import AccountBalance from "components/src/components/displayDataComponents/wallet/AccountBalance/AccountBalance";
import PaymentInfo from "components/src/components/displayDataComponents/wallet/PaymetInfo/PaymentInfo";
import TransactionHistory from "components/src/components/displayDataComponents/TransactionHistory";
import { withApi } from "../hoc/ApiProvider/Provider";
import { useEffect } from "react";
import { useState } from "react";
import {
    AccountBalanceProps,
    BillingResponse,
    TransactionProps,
} from "components/src/components/displayDataComponents/wallet/BillingOverview/types";

const BillingPage = (props: any) => {
    const [loadingBilling, setLoadingBilling] = useState<boolean>(true);
    const [loadingBalance, setLoadingBalance] = useState<boolean>(true);
    const [billingOverViewData, setBillingOverViewData] = useState<
        TransactionProps[] | null
    >(null);
    const [listBalance, setListBalance] = useState<AccountBalanceProps | null>(
        null
    );
    const listBillingOverView = () => {
        props.api
            .getBillingOverView()
            .then(
                (data: BillingResponse) => setBillingOverViewData(data.data),
                setLoadingBilling(false)
            )
            .catch(() => {
                setLoadingBilling(false);
            });
    };
    const listBalanceAccount = () => {
        props.api
            .getBalance()
            .then((res: AccountBalanceProps) => {
                setListBalance(res), setLoadingBalance(false);
            })
            .catch(() => {
                setLoadingBalance(false);
            });
    };
    useEffect(() => {
        listBillingOverView();
        listBalanceAccount();
    }, [props.api]);
    const handlePayment = () => {
        props.api.getBillingPortal().then((res: { url: string }) => {
            window.open(res.url, "_blank");
        });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {loadingBilling ? (
                    <Skeleton width={"100%"} height={"20rem"} />
                ) : (
                    <BillingOverview
                        title={"Billing Overview"}
                        transactions={billingOverViewData}
                    />
                )}
            </Grid>
            <Grid item xs={12} md={6}>
                {loadingBalance ? (
                    <Skeleton width={"100%"} height={"20rem"} />
                ) : (
                    <AccountBalance
                        balance={listBalance.totalBalance}
                        amountDue={listBalance.amountDue}
                    />
                )}
            </Grid>
            <Grid item xs={12} md={6}>
                <PaymentInfo handleClick={handlePayment} />
            </Grid>
            <Grid item xs={12}>
                <TransactionHistory />
            </Grid>
        </Grid>
    );
};

export default withApi(BillingPage);
