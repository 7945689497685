import Button from '@mui/material/Button';
import React, {useState, type MouseEvent} from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Documentation from "../../../icons/Documentation";
import Info from "../../../icons/Info";
import IconButton from '@mui/material/IconButton';
import Stack from "@mui/material/Stack";
import Cancel from "../../../icons/Cancel";

const AccountBalanceTooltip = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "account-balance" : undefined;

    return (
        <>
            <IconButton aria-describedby={id} onClick={handleClick}>
                <Info color="primary" size="small"/>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    "& .MuiPopover-paper": {
                        width: "325px",
                        backgroundColor: '#F9F9FF',
                        border: '1px solid #9091FE',
                        borderRadius: '16px',
                        padding: "12px 16px",
                        gap: "8px"
                    }
                }}
            >
                <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
                    <Typography color="#000271" fontSize="14px" lineHeight="22px" fontWeight={600} fontFamily="Gilroy">
                        Amount Due
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <Cancel color="primary" size="small"/>
                    </IconButton>
                </Stack>
                <Typography color="#414040" fontSize="12px" lineHeight="18px" fontWeight={600} fontFamily="Gilroy">
                    Our post-paid billing system allows for a negative balance, meaning you'll receive a single charge
                    at the end of your billing cycle if your balance is negative. This charge typically occurs on the
                    date shown on your Amount Due card. However, if your usage surpasses a certain threshold during the
                    cycle, an interim charge will be issued. You can view past charges and invoices in the Payment Info
                    section.
                </Typography>
            </Popover>
        </>
    )
}

export default AccountBalanceTooltip