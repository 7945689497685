import { type BillingOverviewProps } from "./types";
import {
    Button,
    Card,
    CardContent,
    Select,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";
import ByteChip from "../../../icons/ByteChip";
import React, { useEffect, useState } from "react";
import { Clear, Download } from "@mui/icons-material";
import BillingOverviewChart from "./BillingOverviewChart";
import {
    billingMonthRange,
    currencyFormatter,
    monthYearFormat,
    transactionsByMonth,
    transactionUniqueDatesSinceEarliest,
} from "../../../../utils/helpers";


const BillingOverview = (props: BillingOverviewProps) => {
    const { title, transactions, selectedDate } = props;
    const [selectedDay, setSelectedDay] = useState<string | undefined>(
        undefined
    );

    const [billingMonth, setBillingMonth] = useState<Date>(
        selectedDate || new Date()
    );

    const totalUsage = transactionsByMonth(
        transactions,
        billingMonth,
        selectedDay
    ).reduce((acc, transaction) => acc + transaction.amount, 0);

    // useEffect(() => {
    //     console.log(selectedDay);
    // }, [selectedDay]);



    return (
        <Card>
            <CardContent>
                <Stack direction="column" gap="21px" sx={{ width: "100%" }}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography
                            color="#000271"
                            fontSize="18px"
                            lineHeight="32px"
                            fontWeight={600}
                        >
                            {title}
                        </Typography>
                        <Stack
                            direction="row"
                            gap={2}
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            {!!selectedDay && (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<Clear />}
                                    onClick={() => setSelectedDay(undefined)}
                                >
                                    Clear
                                </Button>
                            )}
                            <Typography
                                color="#A3A3A3"
                                fontSize="12px"
                                lineHeight="18px"
                                fontWeight={500}
                            >
                                Billing Month
                            </Typography>
                            <Select
                                value={`${billingMonth.getFullYear()}-${
                                    billingMonth.getMonth() + 1
                                }-01`}
                                onChange={(e) => {
                                    const target =
                                        e.target as HTMLSelectElement;
                                    const date = new Date(target.value);
                                    date.setDate(1);
                                    date.setHours(0, 0, 0, 0);
                                    setBillingMonth(date);
                                }}
                                sx={{
                                    width: "120px",
                                    pt: 1.5,
                                }}
                            >
                                {transactionUniqueDatesSinceEarliest(
                                    transactions
                                )?.map((date) => {
                                    return (
                                        <MenuItem
                                            key={date.toISOString()}
                                            value={`${date.getFullYear()}-${
                                                date.getMonth() + 1
                                            }-01`}
                                        >
                                            {monthYearFormat(date)}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Stack>
                    </Stack>

                    <Stack
                        direction={{ xs: "column", md: "row" }}
                        gap="21px"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Stack
                            direction="column"
                            gap="10px"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <Typography
                                color="#A3A3A3"
                                fontSize="16px"
                                lineHeight="24px"
                                fontWeight={500}
                            >
                                Usage
                            </Typography>

                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <ByteChip
                                    color="secondary"
                                    colorVariant="dark"
                                    size="medium"
                                />
                                <Typography color="#000271" variant="h1">
                                    {currencyFormatter(totalUsage)}
                                </Typography>
                            </Stack>

                            <Typography
                                fontSize="10px"
                                lineHeight="16px"
                                fontWeight={500}
                                sx={{ fontStyle: "italic" }}
                            >
                                {billingMonthRange(billingMonth)}
                            </Typography>

                            <Button
                                variant="text"
                                color="primary"
                                startIcon={<Download fontSize="small" />}
                            >
                                <Typography noWrap>Download Summary</Typography>
                            </Button>
                        </Stack>
                        <BillingOverviewChart
                            transactions={transactions}
                            billingMonth={billingMonth}
                            selectedDay={selectedDay}
                            setSelectedDay={setSelectedDay}
                        />
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default BillingOverview;
