import {ChartDataProps, TransactionProps} from "../components/displayDataComponents/wallet/BillingOverview/types";

export const billingMonthRange = (date: Date): string => {
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const monthFormat = startOfMonth.toLocaleString('default', {month: 'short'});
    return `${monthFormat} ${startOfMonth.getDate()} - ${monthFormat} ${endOfMonth.getDate()}`;
}

export const transactionUniqueDatesSinceEarliest = (transactions: TransactionProps[]): Date[] => {
    const earliestTransaction = transactions?.length > 0 ? transactions?.reduce((acc, transaction) => {
        return transaction.createdAt < acc.createdAt ? transaction : acc;
    }) ?? {createdAt: new Date()} : {createdAt: new Date()};

    const today = new Date();

    today.setHours(0, 0, 0, 0);

    const uniqueMonths: Date[] = [];
    let currentDate = new Date(earliestTransaction.createdAt);

    currentDate.setHours(0, 0, 0, 0);

    while (currentDate <= today) {
        uniqueMonths.push(new Date(currentDate));
        currentDate.setMonth(currentDate.getMonth() + 1);
        currentDate.setDate(1);
    }

    return uniqueMonths;
}

export const monthYearFormat = (date: Date): string => {
    return `${date.toLocaleString('default', {month: 'short'})} ${date.getFullYear()}`;
};

export const tickFormatter = (props: {
    tickValue: number
}) => {
    const {tickValue} = props;
    return `C ${tickValue}`;
}

// export const transactionsByMonth = (transactions: TransactionProps[], billingMonth: Date, day?: string): TransactionProps[] => {
//     return transactions?.filter((transaction) => {
//         const year = new Date(transaction.createdAt).getFullYear();
//         const month = new Date(transaction.createdAt).getMonth();
//         const date = new Date(transaction.createdAt).getDate();
//         return year === billingMonth.getFullYear() && month === billingMonth.getMonth() && (!day || date === +day);
//     });
// }
export const transactionsByMonth = (
    transactions: TransactionProps[] = [], // Default to an empty array
    billingMonth: Date,
    day?: string
): TransactionProps[] => {
    return transactions
        ?.filter((transaction) => {
            const createdAt = new Date(transaction.createdAt);
            const year = createdAt.getFullYear();
            const month = createdAt.getMonth();
            const date = createdAt.getDate();

            return (
                year === billingMonth.getFullYear() &&
                month === billingMonth.getMonth() &&
                (!day || date === +day)
            );
        }) ?? []; // If `transactions` is undefined, return an empty array
};


export const transactionsToChartData = (transactions: TransactionProps[], billingMonth: Date): ChartDataProps[] => {
    const data: ChartDataProps[] = [];

    const lastDateOfMonth = new Date(billingMonth.getFullYear(), billingMonth.getMonth() + 1, 0);

    for (let i = 1; i <= lastDateOfMonth.getDate(); i++) {
        const day = new Date(billingMonth.getFullYear(), billingMonth.getMonth(), i);
        const dayString = day.getDate().toString();
        const newDay: ChartDataProps = {name: dayString};
        data.push(newDay);
    }

    const allAppIds = transactions?.map((transaction) => transaction.relatedEntityData?.appId);

    data.forEach((day) => {
        allAppIds?.forEach((appId) => {
            day[appId] = 0;
        });
    });

    transactions?.forEach((transaction) => {
        const year = new Date(transaction.createdAt).getFullYear();
        const month = new Date(transaction.createdAt).getMonth();
        const date = new Date(transaction.createdAt).getDate();
        const appId = transaction.relatedEntityData?.appId;

        if (year === billingMonth.getFullYear() && month === billingMonth.getMonth()) {
            const dataWithDay = data.find((day) => day.name === date.toString());
            if (dataWithDay) {
                dataWithDay[appId] = +(dataWithDay[appId] || 0) + transaction.amount;
            }
        }
    });

    data.forEach((day) => {
        allAppIds?.forEach((appId) => {
            if (day[appId] === 0) {
                day[appId] = null;
            }
        });
    });

    return data;
}

export const domainFormatter = (dataMin: number, dataMax: number): [number, number] => {
    if (dataMin === Infinity || dataMax === Infinity) {
        return [0, 1];
    }
    if (dataMin === 0 && dataMax === 0) {
        return [0, 1];
    }

    if (dataMin === dataMax) {
        return [0, dataMax];
    }

    const max = Math.ceil(dataMax);
    const min = Math.floor(dataMin);
    const range = max - min;
    const step = Math.ceil(range / 5);
    return [min, max + step - (max % step) + step];
}

export const currencyFormatter = (value: number): string => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    }).format(value).replace('$', '');
}

export const colorLooper = (index: number, colors: string[]): string => {
    return colors[index % colors.length];
}