import { useAuthContext } from "@bytenite/auth/src/hoc/Auth/context";
import DisplayDataTable from "@bytenite/components/src/components/displayDataComponents/_displayDataTable";
import { useStorage } from "@bytenite/components/src/hoc/Storage/LocalStorage";
import { useSnackbar } from '@mui/base';
import { Build, Cancel, Check, PlayArrow } from "@mui/icons-material";
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Skeleton, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomerSchemaResponse, JobJob, JobsDataSourceInfoResponse, JobsJobPreset, WalletGetBalanceResponse } from "../client";
import { useApiContext } from "../hoc/ApiProvider/context";
// @ts-ignore
// @ts-ignore
import { uiSchema as uiSchemaVideoTranscoding } from "@bytenite/components/src/schemas/videoTranscoding";
// @ts-ignore
import JsonSchemaForm from "@bytenite/components/src/components/JsonSchemaForm/JsonSchemaForm";
import { File } from "@bytenite/components/src/components/formComponents/FileExplorer";
import FileSelectionCard from "../components/FileSelectionCard";
import ToolbarCard from "../components/ToolbarCard";
import { EVENT_WALLET_SUBSCRIPTION, EVENT_WALLET_TOPUP } from "../hoc/ApiProvider/Provider";

type RunPageProps = {};

type PendingFile = { id: string, dataSource: string };

const extensionRe = /(\.[\w\d]{2,5})$/

const RunPage: React.FC<RunPageProps> = ({ }) => {
  const auth = useAuthContext()
  const api = useApiContext()
  const storage = useStorage()
  const navigate = useNavigate()
  const snackbar = useSnackbar({ open: true })

  const jobConfigFormRef = useRef<any>()

  const [ready, setReady] = useState(false);
  const [editSettings, setEditSettings] = useState(false)
  const [editInputFile, setEditInputFile] = useState(false)
  const [balance, setBalance] = useState<WalletGetBalanceResponse>()
  const [dataSourceInfo, setDataSourceInfo] = useState<JobsDataSourceInfoResponse>()
  const [pendingJob, setPendingJob] = useState<JobJob>()
  const [schema, setSchema] = useState<CustomerSchemaResponse>()
  const [jobCostEstimation, setJobCostEstimation] = useState(0)
  const [jobPresets, setJobPresets] = useState<JobsJobPreset[]>([])
  const [inputFile, setInputFile] = useState<File>(undefined)

  useEffect(() => {
    Promise.all([
      api.getBalance().then((balanceResp) => setBalance(balanceResp)),
      api.getSchema(api.defaultSchemaId()).then((schema) => setSchema(schema)),
      api.getJobPresets(api.defaultTemplateId()).then((jobPresets) => {
        setJobPresets(jobPresets.filter(p => p.category === 'MP4'))
      }),
      storage.getObject<JobJob>('pending-job').then(async (storedJob) => {
        if (storedJob?.dataSource) {
          setPendingJob(storedJob)
          setInputFile(storedJob.dataSource.params as File)
          if (storedJob.dataSource) {
            const dataSourceInfo = await api.getDataSourceInfo({
              dataSourceDescriptor: "dropbox",
              params: {
                "@type": "type.googleapis.com/bytenite.data_source.DropboxDataSource",
                id: '',
                name: '',
                path: '/',
              }
            }, true)
            setDataSourceInfo(dataSourceInfo)
          }
        } else {
          const pendingFile = await storage.getObject<PendingFile>('pending-file')
          const dataSource = {
            dataSourceDescriptor: "dropbox",
            params: {
              "@type": "type.googleapis.com/bytenite.data_source.DropboxDataSource",
              id: '',
              name: '',
              path: '/',
              tempUrl: '',
              size: 0
            }
          }
          const jobPresets = await api.getJobPresets(api.defaultTemplateId())
          let selectedPreset = jobPresets.find(p => p.id === 'mp4-720p-h264')
          if (!selectedPreset) {
            if (jobPresets.length === 0) {
              throw "Preset not found"
            }
            selectedPreset = jobPresets[0]
          }
          const dataDestination = {
            dataSourceDescriptor: "dropbox",
            params: {
              "@type": "type.googleapis.com/bytenite.data_source.DropboxDataSource",
              //id: pendingFile.id,
              name: '',
              path: '/',
            }
          }
          let jobName = 'Dropbox video'
          if (pendingFile?.id) {
            switch (pendingFile.dataSource) {
              case 'dropbox':
                dataSource.params.id = pendingFile.id
                const dataSourceInfo = await api.getDataSourceInfo(dataSource, true)
                dataSource.params.tempUrl = dataSourceInfo.tempUrl
                dataSource.params.name = dataSourceInfo.filename
                dataSource.params.path = dataSourceInfo.path
                setDataSourceInfo(dataSourceInfo)

                /*const dataDestination = {
                  dataSourceDescriptor: "bucket",
                  params: {}
                }*/


                const name = `${dataSourceInfo.filename.replace(/\.[^/.]+$/, "")} ${selectedPreset.name}.mp4`
                const dataSourcePath = dataSourceInfo.path?.substring(0, dataSourceInfo.path.length - (dataSourceInfo.filename?.length || 0) - 1)
                dataDestination.params.name = name
                dataDestination.params.path = dataSourcePath
                jobName = dataSourceInfo.filename || jobName
                break
              default:
                throw 'Data source not supported'
            }
          } else {
            const dataSourceInfo = await api.getDataSourceInfo(dataSource, true)
            setDataSourceInfo({ filename: "", files: dataSourceInfo.files, id: "", path: "", properties: {}, size: "", tempUrl: "" })
            //throw 'No pending files'
          }

          const job = await api.createNewJob(undefined, dataSource, dataDestination, { preset: selectedPreset.id, data: { ...selectedPreset.params } }, jobName)
          setPendingJob(job)
          setInputFile(job.dataSource.params as File)
          storage.setObject<JobJob>('pending-job', job)
        }

      }),
    ]).then(() => {
      setReady(true)
    })

  }, []);


  const onPaymentEvent = (e: any) => {
    api.getBalance().then((balanceResp) => setBalance(balanceResp))
  }

  useEffect(() => {
    document.addEventListener(EVENT_WALLET_TOPUP, onPaymentEvent)
    document.addEventListener(EVENT_WALLET_SUBSCRIPTION, onPaymentEvent)
    return () => {
      document.removeEventListener(EVENT_WALLET_TOPUP, onPaymentEvent)
      document.removeEventListener(EVENT_WALLET_SUBSCRIPTION, onPaymentEvent)
    }
  }, [])
  const getJobConfigFromRef = () => {
    return jobConfigFormRef.current?.state?.formData
  }

  const updateOutputFile = async (outputFile?: File) => {
    const updatedDestination = Object.assign(pendingJob.dataDestination, { params: outputFile })
    const updatedJob = await api.saveJobDataSource(pendingJob, pendingJob.dataSource, updatedDestination)
    await storage.setObject('pending-job', updatedJob)
    setPendingJob(updatedJob)
  }

  const updateInputFile = async (inputFile?: File) => {
    setEditInputFile(false)
    if (!inputFile || !pendingJob.dataSource) {
      return
    }
    setJobCostEstimation(0)
    setDataSourceInfo(undefined)

    const dataSource = {
      dataSourceDescriptor: "dropbox",
      params: {
        "@type": "type.googleapis.com/bytenite.data_source.DropboxDataSource",
        id: inputFile.id,
        name: '',
        path: '/',
        tempUrl: '',
        size: 0
      }
    }
    const dataSourceInfo = await api.getDataSourceInfo(dataSource, true)
    dataSource.params.tempUrl = dataSourceInfo.tempUrl
    dataSource.params.name = dataSourceInfo.filename
    dataSource.params.path = dataSourceInfo.path || '/'

    const dataDestination = { ...pendingJob.dataDestination }
    if (!dataDestination.params.name) {
      const dataDestinationPath = dataSourceInfo.path?.substring(0, dataSourceInfo.path.length - (dataSourceInfo.filename?.length || 0) - 1)
      const selectedPreset = jobPresets.find(p => p.id === pendingJob?.params?.preset)
      const ext = (selectedPreset?.category || 'mp4').toLowerCase()
      dataDestination.params.name = `${dataSourceInfo.filename.replace(extensionRe, '')}-encoded.${ext}`
      dataDestination.params.path = dataDestinationPath
    }

    const updatedJob = await api.saveJobDataSource(pendingJob, dataSource, dataDestination)
    await storage.setObject('pending-job', updatedJob)
    setPendingJob(updatedJob)
    setDataSourceInfo(dataSourceInfo)
    setReady(true)
    //TODO: set pendingJob?.dataSource?.params
  }

  const updateJobConfig = async () => {
    const formData = getJobConfigFromRef()
    const params = { preset: 'custom', schema: pendingJob.params.schema, selectedOutputs: pendingJob.params.selectedOutputs, data: formData }
    const updatedJob = await api.saveJobParameters(pendingJob, params)
    await storage.setObject('pending-job', updatedJob)
    setPendingJob(updatedJob)
    setEditSettings(false)
  }

  const handleStartJob = async () => {
    if (!pendingJob?.id) {
      return
    }
    setReady(false)
    await storage.remove('pending-job')
    await api.runJob(pendingJob.id)
    navigate('/jobs')
  }

  const handleEstimateJob = async (jobId: string) => {
    setJobCostEstimation(0)
  }

  const handleChangePreset = async (newValue: string) => {
    if (!pendingJob?.id) {
      return
    }
    const selectedPreset = jobPresets.find(p => p.id === newValue)
    if (!selectedPreset) {
      throw "Preset not found"
    }
    const params = { preset: selectedPreset.id, selectedOutputs: (selectedPreset.params.outputs || []).map((o: any, i: number) => i), data: { ...selectedPreset.params } }
    const updatedJob = await api.saveJobParameters(pendingJob, params)
    await storage.setObject('pending-job', updatedJob)
    setPendingJob(updatedJob)
  }

  const startDisabled = !ready || editSettings || editInputFile || !dataSourceInfo?.tempUrl || !pendingJob.dataDestination?.params?.name

  if (!ready) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" pt={4}>
        <Typography variant="h4" align="center" color="secondary.dark">
          Loading...
        </Typography>
        <Grid mt={2} spacing={2} justifyContent="center" container>
          <Grid item xs={12} sm={10} lg={8}>
            <Skeleton width="100%" height={200} />
          </Grid>
          <Grid item xs={12} sm={10} lg={8}>
            <Skeleton width="100%" height={250} />
          </Grid>
          <Grid item xs={12} sm={10} lg={8}>
            <Skeleton width="100%" height={200} />
          </Grid>
        </Grid>
      </Box>
    );
  }
  const settingsToolbarButtons = []
  if (editSettings) {
    settingsToolbarButtons.push(
      <IconButton onClick={() => updateJobConfig()} disabled={!schema?.schema} color="success">
        <Check />
      </IconButton>,
      <IconButton onClick={() => setEditSettings(false)} disabled={!schema?.schema} color="error">
        <Cancel />
      </IconButton>)
  } else {
    settingsToolbarButtons.push(
      <IconButton onClick={() => setEditSettings(!editSettings)} disabled={!schema?.schema} color="secondary">
        <Build />
      </IconButton>
    )
  }

  const settingsInfoText = <>Select your preferred preset for the video encoding job, or click on the wrench icon to build your configuration and set your own parameters. Learn
    more about ByteNite video encoding parameters <a target="_blank" href="https://docs.bytenite.com/docs/video-encoding-parameters">here</a>.</>
  const pricesInfoText = <>In this section, you can view your current account balance and the estimated cost of the video encoding job. Both of these figures are shown in
    ByteChips, a utility token used by ByteNite as a currency for purchasing services on our Computing Platform. You can purchase ByteChips and access your ByteNite Wallet by
    clicking on the “Add ByteChips” button. Additionally, you can compare prices and plans on our <a target="_blank" href="https://www.bytenite.com/pricing">pricing page</a>.</>


  return (
    <>
      <Box pt={3}>
        <Typography variant="h5" align="left" color="secondary.dark">
          Account: {(auth.getUserInfo())?.email}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" pt={4}>
        <Typography variant="h4" align="center" color="secondary.dark">
          Video Encoding
        </Typography>
        <Grid mt={2} spacing={2} justifyContent="center" container>
          <Grid item xs={12} sm={10} lg={8}>
            <FileSelectionCard title="Input file" dataSourceInfo={dataSourceInfo} disabled={!schema?.schema} defaultValue={pendingJob?.dataSource?.params as File}
              infoText="Click the pencil icon to select a file for video encoding. Confirm selection with the green check mark. The selected file will appear below “File name”."
              onChange={(updatedFile) => updateInputFile(updatedFile)} />
          </Grid>
          <Grid item xs={12} sm={10} lg={8}>
            <ToolbarCard title="Settings" sx={{ p: 0, mt: 2 }} toolbarButtons={settingsToolbarButtons} infoText={settingsInfoText}>
              <Box px={2} pt={2} pb={2}>
                <Box px={2}>
                  <FormControl sx={{ mt: 0, mb: 3 }} fullWidth color="secondary" disabled={!pendingJob?.params?.preset}>
                    <InputLabel id={"template-label"}>Select an option</InputLabel>
                    <Select
                      labelId={"template-label"}
                      value={pendingJob?.params?.preset || ''}
                      label="Select an option"
                      renderValue={() => <Typography
                        variant="body1">{jobPresets.find(p => p.id === pendingJob?.params?.preset)?.name || pendingJob?.params?.preset || ''}</Typography>}
                      onChange={(event) => handleChangePreset(event.target.value)}
                    >
                      {jobPresets.map(
                        (val) =>
                          <MenuItem key={val.id} value={val.id}>{val.name}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
                {(editSettings && schema?.schema) ?
                  <Box px={2} pb={2}>
                    <JsonSchemaForm ref={jobConfigFormRef}
                      schema={schema.schema}
                      data={jobConfigFormRef.current ? getJobConfigFromRef() : pendingJob?.params.data}
                      noSubmit={true}
                      liveValidate={false}
                      uiSchema={uiSchemaVideoTranscoding} />
                  </Box> :
                  <DisplayDataTable
                    data={
                      /*,*/
                      (pendingJob?.params?.data?.outputs || []).map((output: any) => ({
                        Format: output.output_type || '',
                        Aspect: output.output_params?.aspect?.resolution?.custom_height ? `${output.output_params?.aspect?.resolution?.custom_height}p` : (output.output_params?.aspect?.resolution?.height || ''),
                        Video: output.output_params?.video?.codec || '',
                        Audio: output.output_params?.audio?.audio_codec || '',
                      }))
                    }
                    columnsNames={["Format", "Aspect", "Video", "Audio"]}
                    columnsOrder={["Format", "Aspect", "Video", "Audio"]}
                    displayTableIfNoData
                  />}
              </Box>
            </ToolbarCard>
          </Grid>
          <Grid item xs={12} sm={10} lg={8} mt={2}>
            <FileSelectionCard title="Output file" dataSourceInfo={dataSourceInfo} disabled={!schema?.schema} defaultValue={pendingJob?.dataDestination?.params as File}
              infoText="Click the pencil icon to select a file for video encoding. Confirm selection with the green check mark. The selected file will appear below “File name”."
              onChange={(updatedFile) => updateOutputFile(updatedFile)} selectOutput />
          </Grid>
          <Grid mt={2} pr={2} item xs={12} sm={10} lg={8}>
            <Box display="flex" justifyContent="end" mb={4}>
              <Button
                sx={{ minWidth: "200px" }}
                size="large"
                variant="contained"
                disabled={startDisabled}
                onClick={() => handleStartJob()}
                endIcon={<PlayArrow />}
              >
                Start encoding
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Snackbar {...snackbar.getRootProps()} />
      </Box>
    </>
  );
};

export default RunPage;
