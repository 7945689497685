import {Grid} from "@mui/material";
import React, {Fragment} from "react";
import {colorLooper, currencyFormatter} from "../../../../utils/helpers";
import {chartColors} from "./BillingOverviewChart";

const CustomToolTip = (props: Record<string, any>) => {
    const {payload, legends} = props;
    return (
        <Grid
            container
            bgcolor="#fff"
            direction="column"
            gap={1}
            p={2}
            sx={{
                fontSize: "12px",
                fontFamily: "Gilroy"
            }}>
            <Grid item sx={{
                color: "#9091FE",
                fontWeight: 600,
                fontSize: "12px",
                fontFamily: "Gilroy"
            }}>
                {props.label}
            </Grid>
            {legends.map((legend: string) => {
                const color = colorLooper(legends.indexOf(legend), chartColors);
                const legendPayload = payload.find((item: Record<string, any>) => item.dataKey === legend);
                if (!legendPayload) return <Fragment key={legend}></Fragment>;
                return (
                    <Grid
                        item
                        key={legend}
                        sx={{color: "#9091FE"}}
                    >
                        <span
                            style={{
                                color: color
                            }}>
                            {legend}
                        </span>: {currencyFormatter(legendPayload?.value)}
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default CustomToolTip;