import React, { useEffect, useState } from "react";
import {
    Routes,
    Route,
    useNavigate,
    BrowserRouter,
    useLocation,
} from "react-router-dom";
import Layout from "./pages/Layout";
import AuthProvider from "@bytenite/auth/src/hoc/Auth/Provider";
import PrivateRoute from "@bytenite/auth/src/hoc/Router/PrivateRoute";
import { byteniteTheme } from "@bytenite/components/src/themes/bytenite-theme/byteniteTheme";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import ApiProvider from "./hoc/ApiProvider/Provider";
import { ApiService as AuthApiService } from "@bytenite/auth/src/services/ApiService";
import env from "./env";
import {
    addSidebarEventListener,
    removeSidebarEventListener,
} from "./services/Events";
import { OAuth2Client } from "@badgateway/oauth2-client";
import { ModalProvider } from "@bytenite/components/src/hoc/Modals/Provider";
import StorageProvider from "@bytenite/components/src/hoc/Storage/LocalStorage";
import { routeData } from "./components/route/RouteData";
import TopBarLayout from "./pages/TopBarLayout";

const serverConfigFactory = (conf) => {
    return {
        ...conf,
        basePath: conf.SERVER_URL
            ? `${conf.PROTOCOL}://${conf.SERVER_URL}/${
                  conf.API_VERSION || "v1"
              }`
            : `${window.location.origin}/${conf.API_VERSION || "v1"}`, //
        redirectUrl: `${window.location.origin}/callback`, //
    };
};
const serverConfig = serverConfigFactory(env);
const AppContent = () => {
    const [envLoading, setEnvLoading] = useState(true);
    const idpServerUrl = import.meta.env.VITE_IDP_SERVER_URL;
    const location = useLocation().pathname;
    useEffect(() => {
        if (location === "/") {
            window.location.href = "/jobs";
        }
    }, [location]);
    useEffect(() => {
        fetch("/.env")
            .then((resp) => {
                return resp.json().then((data) => {
                    Object.assign(serverConfig, serverConfigFactory(data));
                });
            })
            .finally(() => {
                setEnvLoading(false);
            });
    }, []);

    if (envLoading) {
        return <></>;
    }

    const authApiService = new AuthApiService({ ...serverConfig });

    const oauthClient = new OAuth2Client({
        // The base URI of your OAuth2 server
        server: serverConfig.AUTH_SERVER,
        fetch: fetch.bind(window),
        discoveryEndpoint: "/.well-known/openid-configuration",
        authorizationEndpoint: "/oauth2/auth",
        // OAuth2 client id
        clientId: "bytenite-app",
        //authenticationMethod: 'client_secret_post'
    });

    const withLayout = (component) => (
        <Layout
            addSidebarEventListener={addSidebarEventListener}
            removeSidebarEventListener={removeSidebarEventListener}
            logout={authApiService.signOut}
        >
            {component}
        </Layout>
    );
    const checkRouteData = (item) => {
        if (item.layout && item.private) {
            return withLayout(<PrivateRoute component={item.element} />);
        } else if (!item.layout && item.private) {
            return <PrivateRoute component={item.element} />;
        } else if (item.layout && !item.private) {
            return withLayout(<item.element />);
        } else if (item.topBar) {
            return (
                <TopBarLayout
                    showProfile={item.showProfile ? item.showProfile : false}
                >
                    <item.element />
                </TopBarLayout>
            );
        }
        return <item.element />;
    };

    return (
        <ModalProvider>
            <StorageProvider>
                <AuthProvider
                    oauthClient={oauthClient}
                    apiService={authApiService}
                    redirectUri={serverConfig.redirectUrl}
                    idpServerUrl={idpServerUrl}
                >
                    <ApiProvider apiConfig={serverConfig}>
                        <Routes>
                            {routeData.map((item, index) => (
                                <Route
                                    key={index}
                                    path={item.path}
                                    element={checkRouteData(item)}
                                />
                            ))}
                            {/* <Route

                                path="/"
                                element={withLayout(
                                    <PrivateRoute component={EncodingPage} />
                                )}
                            />
                            <Route
                                path="/callback"
                                element={<CallbackPage />}
                            />
                            <Route path="/logout" element={<LogoutPage />} />
                            <Route
                                path="/auth/login"
                                element={<SigninPage />}
                            />
                            <Route
                                path="/connectors/dropbox"
                                element={
                                    <TopBarLayout>
                                        <DropboxAuthPage />
                                    </TopBarLayout>
                                }
                            />
                            <Route
                                path="/conectors_callback"
                                element={
                                    <TopBarLayout>
                                        <ConnectorsCallback />
                                    </TopBarLayout>
                                }
                            />
                            <Route
                                path="/convert/:connector"
                                element={
                                    <TopBarLayout showProfile>
                                        <RunJobLitePage />
                                    </TopBarLayout>
                                }
                            />
                            <Route
                                path="/encoding"
                                element={withLayout(
                                    <PrivateRoute component={EncodingPage} />
                                )}
                            /> */}
                            {/* <Route path="/stats" element={withLayout(<PrivateRoute component={StatsPage}/>)}/> */}
                            {/* <Route

                                path="/documentation"
                                element={withLayout(
                                    <PrivateRoute
                                        component={DocumentationPage}
                                    />
                                )}
                            />
                            <Route
                                path="/help"
                                element={withLayout(
                                    <PrivateRoute component={SupportPage} />
                                )}
                            />
                            <Route
                                path="/profile"
                                element={withLayout(
                                    <PrivateRoute component={ProfilePage} />
                                )}
                            />
                            <Route
                                path="/wallet"
                                element={withLayout(
                                    <PrivateRoute component={WalletPage} />
                                )}
                            />
                            <Route
                                path="/jobs/:id"
                                element={withLayout(
                                    <PrivateRoute component={JobEditorPage} />
                                )}
                            /> */}
                        </Routes>
                    </ApiProvider>
                </AuthProvider>
            </StorageProvider>
        </ModalProvider>
    );
};

const App = () => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={byteniteTheme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <BrowserRouter>
                    <AppContent />
                </BrowserRouter>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
